.text-capitalize {
  text-transform: capitalize;
}

.font-size-20 {
  font-size: 20px;
}

.visible {
  display: block;
}

.inline {
  display: inline;
}

.inline-block {
  display: inline-block;
}

.flex {
  display: flex;
}

.inline-flex {
  display: inline-flex;
}

.grid {
  display: grid;
}

.inline-grid {
  display: inline-grid;
}

.hidden {
  display: none;
}

.flex-row {
  flex-direction: row;
}

.flex-col {
  flex-direction: column;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-grow-0 {
  flex-grow: 0;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-2 {
  flex-grow: 2;
}

.flex-grow-3 {
  flex-grow: 3;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-center {
  align-content: center;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-stretch {
  align-content: stretch;
}

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.gap-1 {
  gap: 2;
}

.gap-2 {
  gap: 4;
}

.gap-3 {
  gap: 6;
}

.gap-4 {
  gap: 8;
}

.gap-5 {
  gap: 10;
}

.gap-6 {
  gap: 12;
}

.gap-7 {
  gap: 14;
}

.gap-8 {
  gap: 16;
}

.gap-9 {
  gap: 18;
}

.gap-10 {
  gap: 20;
}

.gap-11 {
  gap: 22;
}

.gap-12 {
  gap: 24;
}

.gap-13 {
  gap: 26;
}

.gap-14 {
  gap: 28;
}

.gap-15 {
  gap: 30;
}

.gap-16 {
  gap: 32;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.ma-auto {
  margin: auto;
}

.ma-0 {
  margin: 0;
}

.mx-0 {
  margin: 0;
}

.my-0 {
  margin: 0;
}

.ml-0 {
  margin-left: 0;
}

.mr-0 {
  margin-right: 0;
}

.mt-0 {
  margin-top: 0;
}

.mb-0 {
  margin-bottom: 0;
}

.ma-1 {
  margin: 4px;
}

.mx-1 {
  margin: 0 4px;
}

.my-1 {
  margin: 4px 0;
}

.ml-1 {
  margin-left: 4px;
}

.mr-1 {
  margin-right: 4px;
}

.mt-1 {
  margin-top: 4px;
}

.mb-1 {
  margin-bottom: 4px;
}

.ma-2 {
  margin: 8px;
}

.mx-2 {
  margin: 0 8px;
}

.my-2 {
  margin: 8px 0;
}

.ml-2 {
  margin-left: 8px;
}

.mr-2 {
  margin-right: 8px;
}

.mt-2 {
  margin-top: 8px;
}

.mb-2 {
  margin-bottom: 8px;
}

.ma-3 {
  margin: 12px;
}

.mx-3 {
  margin: 0 12px;
}

.my-3 {
  margin: 12px 0;
}

.ml-3 {
  margin-left: 12px;
}

.mr-3 {
  margin-right: 12px;
}

.mt-3 {
  margin-top: 12px;
}

.mb-3 {
  margin-bottom: 12px;
}

.ma-4 {
  margin: 16px;
}

.mx-4 {
  margin: 0 16px;
}

.my-4 {
  margin: 16px 0;
}

.ml-4 {
  margin-left: 16px;
}

.mr-4 {
  margin-right: 16px;
}

.mt-4 {
  margin-top: 16px;
}

.mb-4 {
  margin-bottom: 16px;
}

.ma-5 {
  margin: 20px;
}

.mx-5 {
  margin: 0 20px;
}

.my-5 {
  margin: 20px 0;
}

.ml-5 {
  margin-left: 20px;
}

.mr-5 {
  margin-right: 20px;
}

.mt-5 {
  margin-top: 20px;
}

.mb-5 {
  margin-bottom: 20px;
}

.ma-6 {
  margin: 24px;
}

.mx-6 {
  margin: 0 24px;
}

.my-6 {
  margin: 24px 0;
}

.ml-6 {
  margin-left: 24px;
}

.mr-6 {
  margin-right: 24px;
}

.mt-6 {
  margin-top: 24px;
}

.mb-6 {
  margin-bottom: 24px;
}

.ma-7 {
  margin: 28px;
}

.mx-7 {
  margin: 0 28px;
}

.my-7 {
  margin: 28px 0;
}

.ml-7 {
  margin-left: 28px;
}

.mr-7 {
  margin-right: 28px;
}

.mt-7 {
  margin-top: 28px;
}

.mb-7 {
  margin-bottom: 28px;
}

.ma-8 {
  margin: 32px;
}

.mx-8 {
  margin: 0 32px;
}

.my-8 {
  margin: 32px 0;
}

.ml-8 {
  margin-left: 32px;
}

.mr-8 {
  margin-right: 32px;
}

.mt-8 {
  margin-top: 32px;
}

.mb-8 {
  margin-bottom: 32px;
}

.ma-9 {
  margin: 36px;
}

.mx-9 {
  margin: 0 36px;
}

.my-9 {
  margin: 36px 0;
}

.ml-9 {
  margin-left: 36px;
}

.mr-9 {
  margin-right: 36px;
}

.mt-9 {
  margin-top: 36px;
}

.mb-9 {
  margin-bottom: 36px;
}

.ma-10 {
  margin: 40px;
}

.mx-10 {
  margin: 0 40px;
}

.my-10 {
  margin: 40px 0;
}

.ml-10 {
  margin-left: 40px;
}

.mr-10 {
  margin-right: 40px;
}

.mt-10 {
  margin-top: 40px;
}

.mb-10 {
  margin-bottom: 40px;
}

.ma-11 {
  margin: 44px;
}

.mx-11 {
  margin: 0 44px;
}

.my-11 {
  margin: 44px 0;
}

.ml-11 {
  margin-left: 44px;
}

.mr-11 {
  margin-right: 44px;
}

.mt-11 {
  margin-top: 44px;
}

.mb-11 {
  margin-bottom: 44px;
}

.ma-12 {
  margin: 48px;
}

.mx-12 {
  margin: 0 48px;
}

.my-12 {
  margin: 48px 0;
}

.ml-12 {
  margin-left: 48px;
}

.mr-12 {
  margin-right: 48px;
}

.mt-12 {
  margin-top: 48px;
}

.mb-12 {
  margin-bottom: 48px;
}

.ma-13 {
  margin: 52px;
}

.mx-13 {
  margin: 0 52px;
}

.my-13 {
  margin: 52px 0;
}

.ml-13 {
  margin-left: 52px;
}

.mr-13 {
  margin-right: 52px;
}

.mt-13 {
  margin-top: 52px;
}

.mb-13 {
  margin-bottom: 52px;
}

.ma-14 {
  margin: 56px;
}

.mx-14 {
  margin: 0 56px;
}

.my-14 {
  margin: 56px 0;
}

.ml-14 {
  margin-left: 56px;
}

.mr-14 {
  margin-right: 56px;
}

.mt-14 {
  margin-top: 56px;
}

.mb-14 {
  margin-bottom: 56px;
}

.ma-15 {
  margin: 60px;
}

.mx-15 {
  margin: 0 60px;
}

.my-15 {
  margin: 60px 0;
}

.ml-15 {
  margin-left: 60px;
}

.mr-15 {
  margin-right: 60px;
}

.mt-15 {
  margin-top: 60px;
}

.mb-15 {
  margin-bottom: 60px;
}

.ma-16 {
  margin: 64px;
}

.mx-16 {
  margin: 0 64px;
}

.my-16 {
  margin: 64px 0;
}

.ml-16 {
  margin-left: 64px;
}

.mr-16 {
  margin-right: 64px;
}

.mt-16 {
  margin-top: 64px;
}

.mb-16 {
  margin-bottom: 64px;
}

.overflow {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-visible {
  overflow: visible;
}

.overflow-scroll {
  overflow: scroll;
}

.x-auto {
  overflow-x: auto;
}

.x-hidden {
  overflow-x: hidden;
}

.x-visible {
  overflow-x: visible;
}

.x-scroll {
  overflow-x: scroll;
}

.y-auto {
  overflow-y: auto;
}

.y-hidden {
  overflow-y: hidden;
}

.y-visible {
  overflow-y: visible;
}

.y-scroll {
  overflow-y: scroll;
}

.pa-auto {
  padding: auto;
}

.pa-0 {
  padding: 0;
}

.px-0 {
  padding: 0;
}

.py-0 {
  padding: 0;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.pt-0 {
  padding-top: 0;
}

.pb-0 {
  padding-bottom: 0;
}

.pa-1 {
  padding: 4px;
}

.px-1 {
  padding: 0 4px;
}

.py-1 {
  padding: 4px 0;
}

.pl-1 {
  padding-left: 4px;
}

.pr-1 {
  padding-right: 4px;
}

.pt-1 {
  padding-top: 4px;
}

.pb-1 {
  padding-bottom: 4px;
}

.pa-2 {
  padding: 8px;
}

.px-2 {
  padding: 0 8px;
}

.py-2 {
  padding: 8px 0;
}

.pl-2 {
  padding-left: 8px;
}

.pr-2 {
  padding-right: 8px;
}

.pt-2 {
  padding-top: 8px;
}

.pb-2 {
  padding-bottom: 8px;
}

.pa-3 {
  padding: 12px;
}

.px-3 {
  padding: 0 12px;
}

.py-3 {
  padding: 12px 0;
}

.pl-3 {
  padding-left: 12px;
}

.pr-3 {
  padding-right: 12px;
}

.pt-3 {
  padding-top: 12px;
}

.pb-3 {
  padding-bottom: 12px;
}

.pa-4 {
  padding: 16px;
}

.px-4 {
  padding: 0 16px;
}

.py-4 {
  padding: 16px 0;
}

.pl-4 {
  padding-left: 16px;
}

.pr-4 {
  padding-right: 16px;
}

.pt-4 {
  padding-top: 16px;
}

.pb-4 {
  padding-bottom: 16px;
}

.pa-5 {
  padding: 20px;
}

.px-5 {
  padding: 0 20px;
}

.py-5 {
  padding: 20px 0;
}

.pl-5 {
  padding-left: 20px;
}

.pr-5 {
  padding-right: 20px;
}

.pt-5 {
  padding-top: 20px;
}

.pb-5 {
  padding-bottom: 20px;
}

.pa-6 {
  padding: 24px;
}

.px-6 {
  padding: 0 24px;
}

.py-6 {
  padding: 24px 0;
}

.pl-6 {
  padding-left: 24px;
}

.pr-6 {
  padding-right: 24px;
}

.pt-6 {
  padding-top: 24px;
}

.pb-6 {
  padding-bottom: 24px;
}

.pa-7 {
  padding: 28px;
}

.px-7 {
  padding: 0 28px;
}

.py-7 {
  padding: 28px 0;
}

.pl-7 {
  padding-left: 28px;
}

.pr-7 {
  padding-right: 28px;
}

.pt-7 {
  padding-top: 28px;
}

.pb-7 {
  padding-bottom: 28px;
}

.pa-8 {
  padding: 32px;
}

.px-8 {
  padding: 0 32px;
}

.py-8 {
  padding: 32px 0;
}

.pl-8 {
  padding-left: 32px;
}

.pr-8 {
  padding-right: 32px;
}

.pt-8 {
  padding-top: 32px;
}

.pb-8 {
  padding-bottom: 32px;
}

.pa-9 {
  padding: 36px;
}

.px-9 {
  padding: 0 36px;
}

.py-9 {
  padding: 36px 0;
}

.pl-9 {
  padding-left: 36px;
}

.pr-9 {
  padding-right: 36px;
}

.pt-9 {
  padding-top: 36px;
}

.pb-9 {
  padding-bottom: 36px;
}

.pa-10 {
  padding: 40px;
}

.px-10 {
  padding: 0 40px;
}

.py-10 {
  padding: 40px 0;
}

.pl-10 {
  padding-left: 40px;
}

.pr-10 {
  padding-right: 40px;
}

.pt-10 {
  padding-top: 40px;
}

.pb-10 {
  padding-bottom: 40px;
}

.pa-11 {
  padding: 44px;
}

.px-11 {
  padding: 0 44px;
}

.py-11 {
  padding: 44px 0;
}

.pl-11 {
  padding-left: 44px;
}

.pr-11 {
  padding-right: 44px;
}

.pt-11 {
  padding-top: 44px;
}

.pb-11 {
  padding-bottom: 44px;
}

.pa-12 {
  padding: 48px;
}

.px-12 {
  padding: 0 48px;
}

.py-12 {
  padding: 48px 0;
}

.pl-12 {
  padding-left: 48px;
}

.pr-12 {
  padding-right: 48px;
}

.pt-12 {
  padding-top: 48px;
}

.pb-12 {
  padding-bottom: 48px;
}

.pa-13 {
  padding: 52px;
}

.px-13 {
  padding: 0 52px;
}

.py-13 {
  padding: 52px 0;
}

.pl-13 {
  padding-left: 52px;
}

.pr-13 {
  padding-right: 52px;
}

.pt-13 {
  padding-top: 52px;
}

.pb-13 {
  padding-bottom: 52px;
}

.pa-14 {
  padding: 56px;
}

.px-14 {
  padding: 0 56px;
}

.py-14 {
  padding: 56px 0;
}

.pl-14 {
  padding-left: 56px;
}

.pr-14 {
  padding-right: 56px;
}

.pt-14 {
  padding-top: 56px;
}

.pb-14 {
  padding-bottom: 56px;
}

.pa-15 {
  padding: 60px;
}

.px-15 {
  padding: 0 60px;
}

.py-15 {
  padding: 60px 0;
}

.pl-15 {
  padding-left: 60px;
}

.pr-15 {
  padding-right: 60px;
}

.pt-15 {
  padding-top: 60px;
}

.pb-15 {
  padding-bottom: 60px;
}

.pa-16 {
  padding: 64px;
}

.px-16 {
  padding: 0 64px;
}

.py-16 {
  padding: 64px 0;
}

.pl-16 {
  padding-left: 64px;
}

.pr-16 {
  padding-right: 64px;
}

.pt-16 {
  padding-top: 64px;
}

.pb-16 {
  padding-bottom: 64px;
}

.static {
  position: static;
}

.fixed {
  position: fixed;
}

.absolute {
  position: absolute;
}

.relative {
  position: relative;
}

.sticky {
  position: sticky;
}

.top-0 {
  top: 0;
}

.top-1 {
  top: 2px;
}

.top-2 {
  top: 4px;
}

.top-3 {
  top: 6px;
}

.top-4 {
  top: 8px;
}

.top-5 {
  top: 10px;
}

.top-6 {
  top: 12px;
}

.top-7 {
  top: 14px;
}

.top-8 {
  top: 16px;
}

.top-9 {
  top: 18px;
}

.top-10 {
  top: 20px;
}

.top-11 {
  top: 22px;
}

.top-12 {
  top: 24px;
}

.top-13 {
  top: 26px;
}

.top-14 {
  top: 28px;
}

.top-15 {
  top: 30px;
}

.top-16 {
  top: 32px;
}

.bottom-0 {
  bottom: 0;
}

.bottom-1 {
  bottom: 2px;
}

.bottom-2 {
  bottom: 4px;
}

.bottom-3 {
  bottom: 6px;
}

.bottom-4 {
  bottom: 8px;
}

.bottom-5 {
  bottom: 10px;
}

.bottom-6 {
  bottom: 12px;
}

.bottom-7 {
  bottom: 14px;
}

.bottom-8 {
  bottom: 16px;
}

.bottom-9 {
  bottom: 18px;
}

.bottom-10 {
  bottom: 20px;
}

.bottom-11 {
  bottom: 22px;
}

.bottom-12 {
  bottom: 24px;
}

.bottom-13 {
  bottom: 26px;
}

.bottom-14 {
  bottom: 28px;
}

.bottom-15 {
  bottom: 30px;
}

.bottom-16 {
  bottom: 32px;
}

.left-0 {
  left: 0;
}

.left-1 {
  left: 2px;
}

.left-2 {
  left: 4px;
}

.left-3 {
  left: 6px;
}

.left-4 {
  left: 8px;
}

.left-5 {
  left: 10px;
}

.left-6 {
  left: 12px;
}

.left-7 {
  left: 14px;
}

.left-8 {
  left: 16px;
}

.left-9 {
  left: 18px;
}

.left-10 {
  left: 20px;
}

.left-11 {
  left: 22px;
}

.left-12 {
  left: 24px;
}

.left-13 {
  left: 26px;
}

.left-14 {
  left: 28px;
}

.left-15 {
  left: 30px;
}

.left-16 {
  left: 32px;
}

.right-0 {
  right: 0;
}

.right-1 {
  right: 2px;
}

.right-2 {
  right: 4px;
}

.right-3 {
  right: 6px;
}

.right-4 {
  right: 8px;
}

.right-5 {
  right: 10px;
}

.right-6 {
  right: 12px;
}

.right-7 {
  right: 14px;
}

.right-8 {
  right: 16px;
}

.right-9 {
  right: 18px;
}

.right-10 {
  right: 20px;
}

.right-11 {
  right: 22px;
}

.right-12 {
  right: 24px;
}

.right-13 {
  right: 26px;
}

.right-14 {
  right: 28px;
}

.right-15 {
  right: 30px;
}

.right-16 {
  right: 32px;
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-3 {
  z-index: 3;
}

.z-index-auto {
  z-index: auto;
}

.z-index-top {
  z-index: 99999;
}

.z-index-bottom {
  z-index: -99999;
}

.row {
  display: flex;
  flex-wrap: wrap;
}

.col-1 {
  flex: 0 0 8.3333%;
  max-width: 8.3333%;
}

.col-2 {
  flex: 0 0 16.6667%;
  max-width: 16.6667%;
}

.col-3 {
  flex: 0 0 25%;
  max-width: 25%;
}

.col-4 {
  flex: 0 0 33.3333%;
  max-width: 33.3333%;
}

.col-5 {
  flex: 0 0 41.6667%;
  max-width: 41.6667%;
}

.col-6 {
  flex: 0 0 50%;
  max-width: 50%;
}

.col-7 {
  flex: 0 0 58.3333%;
  max-width: 58.3333%;
}

.col-8 {
  flex: 0 0 66.6667%;
  max-width: 66.6667%;
}

.col-9 {
  flex: 0 0 75%;
  max-width: 75%;
}

.col-10 {
  flex: 0 0 83.3333%;
  max-width: 83.3333%;
}

.col-11 {
  flex: 0 0 91.6667%;
  max-width: 91.6667%;
}

.col-12 {
  flex: 0 0 100%;
  max-width: 100%;
}

.h-auto {
  height: auto;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.w-auto {
  width: auto;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.font-bold {
  font-weight: bold;
}

.font-normal {
  font-weight: normal;
}

.font-light {
  font-weight: light;
}

.font-italic {
  font-style: italic;
}

.font-underline {
  text-decoration: underline;
}

.font-overline {
  text-decoration: overline;
}

.font-line-through {
  text-decoration: line-through;
}

.cursor-alias {
  cursor: alias;
}

.cursor-all-scroll {
  cursor: all-scroll;
}

.cursor-auto {
  cursor: auto;
}

.cursor-cell {
  cursor: cell;
}

.cursor-col-resize {
  cursor: col-resize;
}

.cursor-context-menu {
  cursor: context-menu;
}

.cursor-copy {
  cursor: copy;
}

.cursor-crosshair {
  cursor: crosshair;
}

.cursor-default {
  cursor: default;
}

.cursor-e-resize {
  cursor: e-resize;
}

.cursor-ew-resize {
  cursor: ew-resize;
}

.cursor-grab {
  cursor: grab;
}

.cursor-grabbing {
  cursor: grabbing;
}

.cursor-help {
  cursor: help;
}

.cursor-move {
  cursor: move;
}

.cursor-n-resize {
  cursor: n-resize;
}

.cursor-ne-resize {
  cursor: ne-resize;
}

.cursor-nesw-resize {
  cursor: nesw-resize;
}

.cursor-ns-resize {
  cursor: ns-resize;
}

.cursor-nw-resize {
  cursor: nw-resize;
}

.cursor-nwse-resize {
  cursor: nwse-resize;
}

.cursor-no-drop {
  cursor: no-drop;
}

.cursor-none {
  cursor: none;
}

.cursor-not-allowed {
  cursor: not-allowed;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-progress {
  cursor: progress;
}

.cursor-row-resize {
  cursor: row-resize;
}

.cursor-s-resize {
  cursor: s-resize;
}

.cursor-se-resize {
  cursor: se-resize;
}

.cursor-sw-resize {
  cursor: sw-resize;
}

.cursor-text {
  cursor: text;
}

.cursor-w-resize {
  cursor: w-resize;
}

.cursor-wait {
  cursor: wait;
}

.cursor-zoom-in {
  cursor: zoom-in;
}

.cursor-zoom-out {
  cursor: zoom-out;
}

@media (width <= 576px) {
  .sm-visible {
    display: block;
  }

  .sm-inline {
    display: inline;
  }

  .sm-inline-block {
    display: inline-block;
  }

  .sm-flex {
    display: flex;
  }

  .sm-inline-flex {
    display: inline-flex;
  }

  .sm-grid {
    display: grid;
  }

  .sm-inline-grid {
    display: inline-grid;
  }

  .sm-hidden {
    display: none;
  }

  .sm-flex-row {
    flex-direction: row;
  }

  .sm-flex-col {
    flex-direction: column;
  }

  .sm-flex-row-reverse {
    flex-direction: row-reverse;
  }

  .sm-flex-col-reverse {
    flex-direction: column-reverse;
  }

  .sm-flex-grow-0 {
    flex-grow: 0;
  }

  .sm-flex-grow-1 {
    flex-grow: 1;
  }

  .sm-flex-grow-2 {
    flex-grow: 2;
  }

  .sm-flex-grow-3 {
    flex-grow: 3;
  }

  .sm-flex-wrap {
    flex-wrap: wrap;
  }

  .sm-flex-nowrap {
    flex-wrap: nowrap;
  }

  .sm-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .sm-justify-start {
    justify-content: flex-start;
  }

  .sm-justify-end {
    justify-content: flex-end;
  }

  .sm-justify-center {
    justify-content: center;
  }

  .sm-justify-between {
    justify-content: space-between;
  }

  .sm-justify-around {
    justify-content: space-around;
  }

  .sm-justify-evenly {
    justify-content: space-evenly;
  }

  .sm-items-start {
    align-items: flex-start;
  }

  .sm-items-end {
    align-items: flex-end;
  }

  .sm-items-center {
    align-items: center;
  }

  .sm-items-baseline {
    align-items: baseline;
  }

  .sm-items-stretch {
    align-items: stretch;
  }

  .sm-content-start {
    align-content: flex-start;
  }

  .sm-content-end {
    align-content: flex-end;
  }

  .sm-content-center {
    align-content: center;
  }

  .sm-content-between {
    align-content: space-between;
  }

  .sm-content-around {
    align-content: space-around;
  }

  .sm-content-stretch {
    align-content: stretch;
  }

  .sm-self-auto {
    align-self: auto;
  }

  .sm-self-start {
    align-self: flex-start;
  }

  .sm-self-end {
    align-self: flex-end;
  }

  .sm-self-center {
    align-self: center;
  }

  .sm-self-stretch {
    align-self: stretch;
  }

  .sm-gap-1 {
    gap: 2;
  }

  .sm-gap-2 {
    gap: 4;
  }

  .sm-gap-3 {
    gap: 6;
  }

  .sm-gap-4 {
    gap: 8;
  }

  .sm-gap-5 {
    gap: 10;
  }

  .sm-gap-6 {
    gap: 12;
  }

  .sm-gap-7 {
    gap: 14;
  }

  .sm-gap-8 {
    gap: 16;
  }

  .sm-gap-9 {
    gap: 18;
  }

  .sm-gap-10 {
    gap: 20;
  }

  .sm-gap-11 {
    gap: 22;
  }

  .sm-gap-12 {
    gap: 24;
  }

  .sm-gap-13 {
    gap: 26;
  }

  .sm-gap-14 {
    gap: 28;
  }

  .sm-gap-15 {
    gap: 30;
  }

  .sm-gap-16 {
    gap: 32;
  }

  .sm-float-left {
    float: left;
  }

  .sm-float-right {
    float: right;
  }

  .sm-ma-auto {
    margin: auto;
  }

  .sm-ma-0 {
    margin: 0;
  }

  .sm-mx-0 {
    margin: 0;
  }

  .sm-my-0 {
    margin: 0;
  }

  .sm-ml-0 {
    margin-left: 0;
  }

  .sm-mr-0 {
    margin-right: 0;
  }

  .sm-mt-0 {
    margin-top: 0;
  }

  .sm-mb-0 {
    margin-bottom: 0;
  }

  .sm-ma-1 {
    margin: 4px;
  }

  .sm-mx-1 {
    margin: 0 4px;
  }

  .sm-my-1 {
    margin: 4px 0;
  }

  .sm-ml-1 {
    margin-left: 4px;
  }

  .sm-mr-1 {
    margin-right: 4px;
  }

  .sm-mt-1 {
    margin-top: 4px;
  }

  .sm-mb-1 {
    margin-bottom: 4px;
  }

  .sm-ma-2 {
    margin: 8px;
  }

  .sm-mx-2 {
    margin: 0 8px;
  }

  .sm-my-2 {
    margin: 8px 0;
  }

  .sm-ml-2 {
    margin-left: 8px;
  }

  .sm-mr-2 {
    margin-right: 8px;
  }

  .sm-mt-2 {
    margin-top: 8px;
  }

  .sm-mb-2 {
    margin-bottom: 8px;
  }

  .sm-ma-3 {
    margin: 12px;
  }

  .sm-mx-3 {
    margin: 0 12px;
  }

  .sm-my-3 {
    margin: 12px 0;
  }

  .sm-ml-3 {
    margin-left: 12px;
  }

  .sm-mr-3 {
    margin-right: 12px;
  }

  .sm-mt-3 {
    margin-top: 12px;
  }

  .sm-mb-3 {
    margin-bottom: 12px;
  }

  .sm-ma-4 {
    margin: 16px;
  }

  .sm-mx-4 {
    margin: 0 16px;
  }

  .sm-my-4 {
    margin: 16px 0;
  }

  .sm-ml-4 {
    margin-left: 16px;
  }

  .sm-mr-4 {
    margin-right: 16px;
  }

  .sm-mt-4 {
    margin-top: 16px;
  }

  .sm-mb-4 {
    margin-bottom: 16px;
  }

  .sm-ma-5 {
    margin: 20px;
  }

  .sm-mx-5 {
    margin: 0 20px;
  }

  .sm-my-5 {
    margin: 20px 0;
  }

  .sm-ml-5 {
    margin-left: 20px;
  }

  .sm-mr-5 {
    margin-right: 20px;
  }

  .sm-mt-5 {
    margin-top: 20px;
  }

  .sm-mb-5 {
    margin-bottom: 20px;
  }

  .sm-ma-6 {
    margin: 24px;
  }

  .sm-mx-6 {
    margin: 0 24px;
  }

  .sm-my-6 {
    margin: 24px 0;
  }

  .sm-ml-6 {
    margin-left: 24px;
  }

  .sm-mr-6 {
    margin-right: 24px;
  }

  .sm-mt-6 {
    margin-top: 24px;
  }

  .sm-mb-6 {
    margin-bottom: 24px;
  }

  .sm-ma-7 {
    margin: 28px;
  }

  .sm-mx-7 {
    margin: 0 28px;
  }

  .sm-my-7 {
    margin: 28px 0;
  }

  .sm-ml-7 {
    margin-left: 28px;
  }

  .sm-mr-7 {
    margin-right: 28px;
  }

  .sm-mt-7 {
    margin-top: 28px;
  }

  .sm-mb-7 {
    margin-bottom: 28px;
  }

  .sm-ma-8 {
    margin: 32px;
  }

  .sm-mx-8 {
    margin: 0 32px;
  }

  .sm-my-8 {
    margin: 32px 0;
  }

  .sm-ml-8 {
    margin-left: 32px;
  }

  .sm-mr-8 {
    margin-right: 32px;
  }

  .sm-mt-8 {
    margin-top: 32px;
  }

  .sm-mb-8 {
    margin-bottom: 32px;
  }

  .sm-ma-9 {
    margin: 36px;
  }

  .sm-mx-9 {
    margin: 0 36px;
  }

  .sm-my-9 {
    margin: 36px 0;
  }

  .sm-ml-9 {
    margin-left: 36px;
  }

  .sm-mr-9 {
    margin-right: 36px;
  }

  .sm-mt-9 {
    margin-top: 36px;
  }

  .sm-mb-9 {
    margin-bottom: 36px;
  }

  .sm-ma-10 {
    margin: 40px;
  }

  .sm-mx-10 {
    margin: 0 40px;
  }

  .sm-my-10 {
    margin: 40px 0;
  }

  .sm-ml-10 {
    margin-left: 40px;
  }

  .sm-mr-10 {
    margin-right: 40px;
  }

  .sm-mt-10 {
    margin-top: 40px;
  }

  .sm-mb-10 {
    margin-bottom: 40px;
  }

  .sm-ma-11 {
    margin: 44px;
  }

  .sm-mx-11 {
    margin: 0 44px;
  }

  .sm-my-11 {
    margin: 44px 0;
  }

  .sm-ml-11 {
    margin-left: 44px;
  }

  .sm-mr-11 {
    margin-right: 44px;
  }

  .sm-mt-11 {
    margin-top: 44px;
  }

  .sm-mb-11 {
    margin-bottom: 44px;
  }

  .sm-ma-12 {
    margin: 48px;
  }

  .sm-mx-12 {
    margin: 0 48px;
  }

  .sm-my-12 {
    margin: 48px 0;
  }

  .sm-ml-12 {
    margin-left: 48px;
  }

  .sm-mr-12 {
    margin-right: 48px;
  }

  .sm-mt-12 {
    margin-top: 48px;
  }

  .sm-mb-12 {
    margin-bottom: 48px;
  }

  .sm-ma-13 {
    margin: 52px;
  }

  .sm-mx-13 {
    margin: 0 52px;
  }

  .sm-my-13 {
    margin: 52px 0;
  }

  .sm-ml-13 {
    margin-left: 52px;
  }

  .sm-mr-13 {
    margin-right: 52px;
  }

  .sm-mt-13 {
    margin-top: 52px;
  }

  .sm-mb-13 {
    margin-bottom: 52px;
  }

  .sm-ma-14 {
    margin: 56px;
  }

  .sm-mx-14 {
    margin: 0 56px;
  }

  .sm-my-14 {
    margin: 56px 0;
  }

  .sm-ml-14 {
    margin-left: 56px;
  }

  .sm-mr-14 {
    margin-right: 56px;
  }

  .sm-mt-14 {
    margin-top: 56px;
  }

  .sm-mb-14 {
    margin-bottom: 56px;
  }

  .sm-ma-15 {
    margin: 60px;
  }

  .sm-mx-15 {
    margin: 0 60px;
  }

  .sm-my-15 {
    margin: 60px 0;
  }

  .sm-ml-15 {
    margin-left: 60px;
  }

  .sm-mr-15 {
    margin-right: 60px;
  }

  .sm-mt-15 {
    margin-top: 60px;
  }

  .sm-mb-15 {
    margin-bottom: 60px;
  }

  .sm-ma-16 {
    margin: 64px;
  }

  .sm-mx-16 {
    margin: 0 64px;
  }

  .sm-my-16 {
    margin: 64px 0;
  }

  .sm-ml-16 {
    margin-left: 64px;
  }

  .sm-mr-16 {
    margin-right: 64px;
  }

  .sm-mt-16 {
    margin-top: 64px;
  }

  .sm-mb-16 {
    margin-bottom: 64px;
  }

  .sm-overflow {
    overflow: auto;
  }

  .sm-overflow-hidden {
    overflow: hidden;
  }

  .sm-overflow-visible {
    overflow: visible;
  }

  .sm-overflow-scroll {
    overflow: scroll;
  }

  .sm-x-auto {
    overflow-x: auto;
  }

  .sm-x-hidden {
    overflow-x: hidden;
  }

  .sm-x-visible {
    overflow-x: visible;
  }

  .sm-x-scroll {
    overflow-x: scroll;
  }

  .sm-y-auto {
    overflow-y: auto;
  }

  .sm-y-hidden {
    overflow-y: hidden;
  }

  .sm-y-visible {
    overflow-y: visible;
  }

  .sm-y-scroll {
    overflow-y: scroll;
  }

  .sm-pa-auto {
    padding: auto;
  }

  .sm-pa-0 {
    padding: 0;
  }

  .sm-px-0 {
    padding: 0;
  }

  .sm-py-0 {
    padding: 0;
  }

  .sm-pl-0 {
    padding-left: 0;
  }

  .sm-pr-0 {
    padding-right: 0;
  }

  .sm-pt-0 {
    padding-top: 0;
  }

  .sm-pb-0 {
    padding-bottom: 0;
  }

  .sm-pa-1 {
    padding: 4px;
  }

  .sm-px-1 {
    padding: 0 4px;
  }

  .sm-py-1 {
    padding: 4px 0;
  }

  .sm-pl-1 {
    padding-left: 4px;
  }

  .sm-pr-1 {
    padding-right: 4px;
  }

  .sm-pt-1 {
    padding-top: 4px;
  }

  .sm-pb-1 {
    padding-bottom: 4px;
  }

  .sm-pa-2 {
    padding: 8px;
  }

  .sm-px-2 {
    padding: 0 8px;
  }

  .sm-py-2 {
    padding: 8px 0;
  }

  .sm-pl-2 {
    padding-left: 8px;
  }

  .sm-pr-2 {
    padding-right: 8px;
  }

  .sm-pt-2 {
    padding-top: 8px;
  }

  .sm-pb-2 {
    padding-bottom: 8px;
  }

  .sm-pa-3 {
    padding: 12px;
  }

  .sm-px-3 {
    padding: 0 12px;
  }

  .sm-py-3 {
    padding: 12px 0;
  }

  .sm-pl-3 {
    padding-left: 12px;
  }

  .sm-pr-3 {
    padding-right: 12px;
  }

  .sm-pt-3 {
    padding-top: 12px;
  }

  .sm-pb-3 {
    padding-bottom: 12px;
  }

  .sm-pa-4 {
    padding: 16px;
  }

  .sm-px-4 {
    padding: 0 16px;
  }

  .sm-py-4 {
    padding: 16px 0;
  }

  .sm-pl-4 {
    padding-left: 16px;
  }

  .sm-pr-4 {
    padding-right: 16px;
  }

  .sm-pt-4 {
    padding-top: 16px;
  }

  .sm-pb-4 {
    padding-bottom: 16px;
  }

  .sm-pa-5 {
    padding: 20px;
  }

  .sm-px-5 {
    padding: 0 20px;
  }

  .sm-py-5 {
    padding: 20px 0;
  }

  .sm-pl-5 {
    padding-left: 20px;
  }

  .sm-pr-5 {
    padding-right: 20px;
  }

  .sm-pt-5 {
    padding-top: 20px;
  }

  .sm-pb-5 {
    padding-bottom: 20px;
  }

  .sm-pa-6 {
    padding: 24px;
  }

  .sm-px-6 {
    padding: 0 24px;
  }

  .sm-py-6 {
    padding: 24px 0;
  }

  .sm-pl-6 {
    padding-left: 24px;
  }

  .sm-pr-6 {
    padding-right: 24px;
  }

  .sm-pt-6 {
    padding-top: 24px;
  }

  .sm-pb-6 {
    padding-bottom: 24px;
  }

  .sm-pa-7 {
    padding: 28px;
  }

  .sm-px-7 {
    padding: 0 28px;
  }

  .sm-py-7 {
    padding: 28px 0;
  }

  .sm-pl-7 {
    padding-left: 28px;
  }

  .sm-pr-7 {
    padding-right: 28px;
  }

  .sm-pt-7 {
    padding-top: 28px;
  }

  .sm-pb-7 {
    padding-bottom: 28px;
  }

  .sm-pa-8 {
    padding: 32px;
  }

  .sm-px-8 {
    padding: 0 32px;
  }

  .sm-py-8 {
    padding: 32px 0;
  }

  .sm-pl-8 {
    padding-left: 32px;
  }

  .sm-pr-8 {
    padding-right: 32px;
  }

  .sm-pt-8 {
    padding-top: 32px;
  }

  .sm-pb-8 {
    padding-bottom: 32px;
  }

  .sm-pa-9 {
    padding: 36px;
  }

  .sm-px-9 {
    padding: 0 36px;
  }

  .sm-py-9 {
    padding: 36px 0;
  }

  .sm-pl-9 {
    padding-left: 36px;
  }

  .sm-pr-9 {
    padding-right: 36px;
  }

  .sm-pt-9 {
    padding-top: 36px;
  }

  .sm-pb-9 {
    padding-bottom: 36px;
  }

  .sm-pa-10 {
    padding: 40px;
  }

  .sm-px-10 {
    padding: 0 40px;
  }

  .sm-py-10 {
    padding: 40px 0;
  }

  .sm-pl-10 {
    padding-left: 40px;
  }

  .sm-pr-10 {
    padding-right: 40px;
  }

  .sm-pt-10 {
    padding-top: 40px;
  }

  .sm-pb-10 {
    padding-bottom: 40px;
  }

  .sm-pa-11 {
    padding: 44px;
  }

  .sm-px-11 {
    padding: 0 44px;
  }

  .sm-py-11 {
    padding: 44px 0;
  }

  .sm-pl-11 {
    padding-left: 44px;
  }

  .sm-pr-11 {
    padding-right: 44px;
  }

  .sm-pt-11 {
    padding-top: 44px;
  }

  .sm-pb-11 {
    padding-bottom: 44px;
  }

  .sm-pa-12 {
    padding: 48px;
  }

  .sm-px-12 {
    padding: 0 48px;
  }

  .sm-py-12 {
    padding: 48px 0;
  }

  .sm-pl-12 {
    padding-left: 48px;
  }

  .sm-pr-12 {
    padding-right: 48px;
  }

  .sm-pt-12 {
    padding-top: 48px;
  }

  .sm-pb-12 {
    padding-bottom: 48px;
  }

  .sm-pa-13 {
    padding: 52px;
  }

  .sm-px-13 {
    padding: 0 52px;
  }

  .sm-py-13 {
    padding: 52px 0;
  }

  .sm-pl-13 {
    padding-left: 52px;
  }

  .sm-pr-13 {
    padding-right: 52px;
  }

  .sm-pt-13 {
    padding-top: 52px;
  }

  .sm-pb-13 {
    padding-bottom: 52px;
  }

  .sm-pa-14 {
    padding: 56px;
  }

  .sm-px-14 {
    padding: 0 56px;
  }

  .sm-py-14 {
    padding: 56px 0;
  }

  .sm-pl-14 {
    padding-left: 56px;
  }

  .sm-pr-14 {
    padding-right: 56px;
  }

  .sm-pt-14 {
    padding-top: 56px;
  }

  .sm-pb-14 {
    padding-bottom: 56px;
  }

  .sm-pa-15 {
    padding: 60px;
  }

  .sm-px-15 {
    padding: 0 60px;
  }

  .sm-py-15 {
    padding: 60px 0;
  }

  .sm-pl-15 {
    padding-left: 60px;
  }

  .sm-pr-15 {
    padding-right: 60px;
  }

  .sm-pt-15 {
    padding-top: 60px;
  }

  .sm-pb-15 {
    padding-bottom: 60px;
  }

  .sm-pa-16 {
    padding: 64px;
  }

  .sm-px-16 {
    padding: 0 64px;
  }

  .sm-py-16 {
    padding: 64px 0;
  }

  .sm-pl-16 {
    padding-left: 64px;
  }

  .sm-pr-16 {
    padding-right: 64px;
  }

  .sm-pt-16 {
    padding-top: 64px;
  }

  .sm-pb-16 {
    padding-bottom: 64px;
  }

  .sm-static {
    position: static;
  }

  .sm-fixed {
    position: fixed;
  }

  .sm-absolute {
    position: absolute;
  }

  .sm-relative {
    position: relative;
  }

  .sm-sticky {
    position: sticky;
  }

  .sm-top-0 {
    top: 0;
  }

  .sm-top-1 {
    top: 2px;
  }

  .sm-top-2 {
    top: 4px;
  }

  .sm-top-3 {
    top: 6px;
  }

  .sm-top-4 {
    top: 8px;
  }

  .sm-top-5 {
    top: 10px;
  }

  .sm-top-6 {
    top: 12px;
  }

  .sm-top-7 {
    top: 14px;
  }

  .sm-top-8 {
    top: 16px;
  }

  .sm-top-9 {
    top: 18px;
  }

  .sm-top-10 {
    top: 20px;
  }

  .sm-top-11 {
    top: 22px;
  }

  .sm-top-12 {
    top: 24px;
  }

  .sm-top-13 {
    top: 26px;
  }

  .sm-top-14 {
    top: 28px;
  }

  .sm-top-15 {
    top: 30px;
  }

  .sm-top-16 {
    top: 32px;
  }

  .sm-bottom-0 {
    bottom: 0;
  }

  .sm-bottom-1 {
    bottom: 2px;
  }

  .sm-bottom-2 {
    bottom: 4px;
  }

  .sm-bottom-3 {
    bottom: 6px;
  }

  .sm-bottom-4 {
    bottom: 8px;
  }

  .sm-bottom-5 {
    bottom: 10px;
  }

  .sm-bottom-6 {
    bottom: 12px;
  }

  .sm-bottom-7 {
    bottom: 14px;
  }

  .sm-bottom-8 {
    bottom: 16px;
  }

  .sm-bottom-9 {
    bottom: 18px;
  }

  .sm-bottom-10 {
    bottom: 20px;
  }

  .sm-bottom-11 {
    bottom: 22px;
  }

  .sm-bottom-12 {
    bottom: 24px;
  }

  .sm-bottom-13 {
    bottom: 26px;
  }

  .sm-bottom-14 {
    bottom: 28px;
  }

  .sm-bottom-15 {
    bottom: 30px;
  }

  .sm-bottom-16 {
    bottom: 32px;
  }

  .sm-left-0 {
    left: 0;
  }

  .sm-left-1 {
    left: 2px;
  }

  .sm-left-2 {
    left: 4px;
  }

  .sm-left-3 {
    left: 6px;
  }

  .sm-left-4 {
    left: 8px;
  }

  .sm-left-5 {
    left: 10px;
  }

  .sm-left-6 {
    left: 12px;
  }

  .sm-left-7 {
    left: 14px;
  }

  .sm-left-8 {
    left: 16px;
  }

  .sm-left-9 {
    left: 18px;
  }

  .sm-left-10 {
    left: 20px;
  }

  .sm-left-11 {
    left: 22px;
  }

  .sm-left-12 {
    left: 24px;
  }

  .sm-left-13 {
    left: 26px;
  }

  .sm-left-14 {
    left: 28px;
  }

  .sm-left-15 {
    left: 30px;
  }

  .sm-left-16 {
    left: 32px;
  }

  .sm-right-0 {
    right: 0;
  }

  .sm-right-1 {
    right: 2px;
  }

  .sm-right-2 {
    right: 4px;
  }

  .sm-right-3 {
    right: 6px;
  }

  .sm-right-4 {
    right: 8px;
  }

  .sm-right-5 {
    right: 10px;
  }

  .sm-right-6 {
    right: 12px;
  }

  .sm-right-7 {
    right: 14px;
  }

  .sm-right-8 {
    right: 16px;
  }

  .sm-right-9 {
    right: 18px;
  }

  .sm-right-10 {
    right: 20px;
  }

  .sm-right-11 {
    right: 22px;
  }

  .sm-right-12 {
    right: 24px;
  }

  .sm-right-13 {
    right: 26px;
  }

  .sm-right-14 {
    right: 28px;
  }

  .sm-right-15 {
    right: 30px;
  }

  .sm-right-16 {
    right: 32px;
  }

  .sm-z-index-0 {
    z-index: 0;
  }

  .sm-z-index-1 {
    z-index: 1;
  }

  .sm-z-index-2 {
    z-index: 2;
  }

  .sm-z-index-3 {
    z-index: 3;
  }

  .sm-z-index-auto {
    z-index: auto;
  }

  .sm-z-index-top {
    z-index: 99999;
  }

  .sm-z-index-bottom {
    z-index: -99999;
  }

  .sm-row {
    display: flex;
    flex-wrap: wrap;
  }

  .sm-col-1 {
    flex: 0 0 8.3333%;
    max-width: 8.3333%;
  }

  .sm-col-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .sm-col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .sm-col-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .sm-col-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .sm-col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .sm-col-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .sm-col-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .sm-col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .sm-col-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .sm-col-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .sm-col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .sm-h-auto {
    height: auto;
  }

  .sm-h-full {
    height: 100%;
  }

  .sm-h-screen {
    height: 100vh;
  }

  .sm-w-auto {
    width: auto;
  }

  .sm-w-full {
    width: 100%;
  }

  .sm-w-screen {
    width: 100vw;
  }

  .sm-text-center {
    text-align: center;
  }

  .sm-text-left {
    text-align: left;
  }

  .sm-text-right {
    text-align: right;
  }

  .sm-font-bold {
    font-weight: bold;
  }

  .sm-font-normal {
    font-weight: normal;
  }

  .sm-font-light {
    font-weight: light;
  }

  .sm-font-italic {
    font-style: italic;
  }

  .sm-font-underline {
    text-decoration: underline;
  }

  .sm-font-overline {
    text-decoration: overline;
  }

  .sm-font-line-through {
    text-decoration: line-through;
  }
}

@media (width <= 768px) {
  .md-visible {
    display: block;
  }

  .md-inline {
    display: inline;
  }

  .md-inline-block {
    display: inline-block;
  }

  .md-flex {
    display: flex;
  }

  .md-inline-flex {
    display: inline-flex;
  }

  .md-grid {
    display: grid;
  }

  .md-inline-grid {
    display: inline-grid;
  }

  .md-hidden {
    display: none;
  }

  .md-flex-row {
    flex-direction: row;
  }

  .md-flex-col {
    flex-direction: column;
  }

  .md-flex-row-reverse {
    flex-direction: row-reverse;
  }

  .md-flex-col-reverse {
    flex-direction: column-reverse;
  }

  .md-flex-grow-0 {
    flex-grow: 0;
  }

  .md-flex-grow-1 {
    flex-grow: 1;
  }

  .md-flex-grow-2 {
    flex-grow: 2;
  }

  .md-flex-grow-3 {
    flex-grow: 3;
  }

  .md-flex-wrap {
    flex-wrap: wrap;
  }

  .md-flex-nowrap {
    flex-wrap: nowrap;
  }

  .md-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .md-justify-start {
    justify-content: flex-start;
  }

  .md-justify-end {
    justify-content: flex-end;
  }

  .md-justify-center {
    justify-content: center;
  }

  .md-justify-between {
    justify-content: space-between;
  }

  .md-justify-around {
    justify-content: space-around;
  }

  .md-justify-evenly {
    justify-content: space-evenly;
  }

  .md-items-start {
    align-items: flex-start;
  }

  .md-items-end {
    align-items: flex-end;
  }

  .md-items-center {
    align-items: center;
  }

  .md-items-baseline {
    align-items: baseline;
  }

  .md-items-stretch {
    align-items: stretch;
  }

  .md-content-start {
    align-content: flex-start;
  }

  .md-content-end {
    align-content: flex-end;
  }

  .md-content-center {
    align-content: center;
  }

  .md-content-between {
    align-content: space-between;
  }

  .md-content-around {
    align-content: space-around;
  }

  .md-content-stretch {
    align-content: stretch;
  }

  .md-self-auto {
    align-self: auto;
  }

  .md-self-start {
    align-self: flex-start;
  }

  .md-self-end {
    align-self: flex-end;
  }

  .md-self-center {
    align-self: center;
  }

  .md-self-stretch {
    align-self: stretch;
  }

  .md-gap-1 {
    gap: 2;
  }

  .md-gap-2 {
    gap: 4;
  }

  .md-gap-3 {
    gap: 6;
  }

  .md-gap-4 {
    gap: 8;
  }

  .md-gap-5 {
    gap: 10;
  }

  .md-gap-6 {
    gap: 12;
  }

  .md-gap-7 {
    gap: 14;
  }

  .md-gap-8 {
    gap: 16;
  }

  .md-gap-9 {
    gap: 18;
  }

  .md-gap-10 {
    gap: 20;
  }

  .md-gap-11 {
    gap: 22;
  }

  .md-gap-12 {
    gap: 24;
  }

  .md-gap-13 {
    gap: 26;
  }

  .md-gap-14 {
    gap: 28;
  }

  .md-gap-15 {
    gap: 30;
  }

  .md-gap-16 {
    gap: 32;
  }

  .md-float-left {
    float: left;
  }

  .md-float-right {
    float: right;
  }

  .md-ma-auto {
    margin: auto;
  }

  .md-ma-0 {
    margin: 0;
  }

  .md-mx-0 {
    margin: 0;
  }

  .md-my-0 {
    margin: 0;
  }

  .md-ml-0 {
    margin-left: 0;
  }

  .md-mr-0 {
    margin-right: 0;
  }

  .md-mt-0 {
    margin-top: 0;
  }

  .md-mb-0 {
    margin-bottom: 0;
  }

  .md-ma-1 {
    margin: 4px;
  }

  .md-mx-1 {
    margin: 0 4px;
  }

  .md-my-1 {
    margin: 4px 0;
  }

  .md-ml-1 {
    margin-left: 4px;
  }

  .md-mr-1 {
    margin-right: 4px;
  }

  .md-mt-1 {
    margin-top: 4px;
  }

  .md-mb-1 {
    margin-bottom: 4px;
  }

  .md-ma-2 {
    margin: 8px;
  }

  .md-mx-2 {
    margin: 0 8px;
  }

  .md-my-2 {
    margin: 8px 0;
  }

  .md-ml-2 {
    margin-left: 8px;
  }

  .md-mr-2 {
    margin-right: 8px;
  }

  .md-mt-2 {
    margin-top: 8px;
  }

  .md-mb-2 {
    margin-bottom: 8px;
  }

  .md-ma-3 {
    margin: 12px;
  }

  .md-mx-3 {
    margin: 0 12px;
  }

  .md-my-3 {
    margin: 12px 0;
  }

  .md-ml-3 {
    margin-left: 12px;
  }

  .md-mr-3 {
    margin-right: 12px;
  }

  .md-mt-3 {
    margin-top: 12px;
  }

  .md-mb-3 {
    margin-bottom: 12px;
  }

  .md-ma-4 {
    margin: 16px;
  }

  .md-mx-4 {
    margin: 0 16px;
  }

  .md-my-4 {
    margin: 16px 0;
  }

  .md-ml-4 {
    margin-left: 16px;
  }

  .md-mr-4 {
    margin-right: 16px;
  }

  .md-mt-4 {
    margin-top: 16px;
  }

  .md-mb-4 {
    margin-bottom: 16px;
  }

  .md-ma-5 {
    margin: 20px;
  }

  .md-mx-5 {
    margin: 0 20px;
  }

  .md-my-5 {
    margin: 20px 0;
  }

  .md-ml-5 {
    margin-left: 20px;
  }

  .md-mr-5 {
    margin-right: 20px;
  }

  .md-mt-5 {
    margin-top: 20px;
  }

  .md-mb-5 {
    margin-bottom: 20px;
  }

  .md-ma-6 {
    margin: 24px;
  }

  .md-mx-6 {
    margin: 0 24px;
  }

  .md-my-6 {
    margin: 24px 0;
  }

  .md-ml-6 {
    margin-left: 24px;
  }

  .md-mr-6 {
    margin-right: 24px;
  }

  .md-mt-6 {
    margin-top: 24px;
  }

  .md-mb-6 {
    margin-bottom: 24px;
  }

  .md-ma-7 {
    margin: 28px;
  }

  .md-mx-7 {
    margin: 0 28px;
  }

  .md-my-7 {
    margin: 28px 0;
  }

  .md-ml-7 {
    margin-left: 28px;
  }

  .md-mr-7 {
    margin-right: 28px;
  }

  .md-mt-7 {
    margin-top: 28px;
  }

  .md-mb-7 {
    margin-bottom: 28px;
  }

  .md-ma-8 {
    margin: 32px;
  }

  .md-mx-8 {
    margin: 0 32px;
  }

  .md-my-8 {
    margin: 32px 0;
  }

  .md-ml-8 {
    margin-left: 32px;
  }

  .md-mr-8 {
    margin-right: 32px;
  }

  .md-mt-8 {
    margin-top: 32px;
  }

  .md-mb-8 {
    margin-bottom: 32px;
  }

  .md-ma-9 {
    margin: 36px;
  }

  .md-mx-9 {
    margin: 0 36px;
  }

  .md-my-9 {
    margin: 36px 0;
  }

  .md-ml-9 {
    margin-left: 36px;
  }

  .md-mr-9 {
    margin-right: 36px;
  }

  .md-mt-9 {
    margin-top: 36px;
  }

  .md-mb-9 {
    margin-bottom: 36px;
  }

  .md-ma-10 {
    margin: 40px;
  }

  .md-mx-10 {
    margin: 0 40px;
  }

  .md-my-10 {
    margin: 40px 0;
  }

  .md-ml-10 {
    margin-left: 40px;
  }

  .md-mr-10 {
    margin-right: 40px;
  }

  .md-mt-10 {
    margin-top: 40px;
  }

  .md-mb-10 {
    margin-bottom: 40px;
  }

  .md-ma-11 {
    margin: 44px;
  }

  .md-mx-11 {
    margin: 0 44px;
  }

  .md-my-11 {
    margin: 44px 0;
  }

  .md-ml-11 {
    margin-left: 44px;
  }

  .md-mr-11 {
    margin-right: 44px;
  }

  .md-mt-11 {
    margin-top: 44px;
  }

  .md-mb-11 {
    margin-bottom: 44px;
  }

  .md-ma-12 {
    margin: 48px;
  }

  .md-mx-12 {
    margin: 0 48px;
  }

  .md-my-12 {
    margin: 48px 0;
  }

  .md-ml-12 {
    margin-left: 48px;
  }

  .md-mr-12 {
    margin-right: 48px;
  }

  .md-mt-12 {
    margin-top: 48px;
  }

  .md-mb-12 {
    margin-bottom: 48px;
  }

  .md-ma-13 {
    margin: 52px;
  }

  .md-mx-13 {
    margin: 0 52px;
  }

  .md-my-13 {
    margin: 52px 0;
  }

  .md-ml-13 {
    margin-left: 52px;
  }

  .md-mr-13 {
    margin-right: 52px;
  }

  .md-mt-13 {
    margin-top: 52px;
  }

  .md-mb-13 {
    margin-bottom: 52px;
  }

  .md-ma-14 {
    margin: 56px;
  }

  .md-mx-14 {
    margin: 0 56px;
  }

  .md-my-14 {
    margin: 56px 0;
  }

  .md-ml-14 {
    margin-left: 56px;
  }

  .md-mr-14 {
    margin-right: 56px;
  }

  .md-mt-14 {
    margin-top: 56px;
  }

  .md-mb-14 {
    margin-bottom: 56px;
  }

  .md-ma-15 {
    margin: 60px;
  }

  .md-mx-15 {
    margin: 0 60px;
  }

  .md-my-15 {
    margin: 60px 0;
  }

  .md-ml-15 {
    margin-left: 60px;
  }

  .md-mr-15 {
    margin-right: 60px;
  }

  .md-mt-15 {
    margin-top: 60px;
  }

  .md-mb-15 {
    margin-bottom: 60px;
  }

  .md-ma-16 {
    margin: 64px;
  }

  .md-mx-16 {
    margin: 0 64px;
  }

  .md-my-16 {
    margin: 64px 0;
  }

  .md-ml-16 {
    margin-left: 64px;
  }

  .md-mr-16 {
    margin-right: 64px;
  }

  .md-mt-16 {
    margin-top: 64px;
  }

  .md-mb-16 {
    margin-bottom: 64px;
  }

  .md-overflow {
    overflow: auto;
  }

  .md-overflow-hidden {
    overflow: hidden;
  }

  .md-overflow-visible {
    overflow: visible;
  }

  .md-overflow-scroll {
    overflow: scroll;
  }

  .md-x-auto {
    overflow-x: auto;
  }

  .md-x-hidden {
    overflow-x: hidden;
  }

  .md-x-visible {
    overflow-x: visible;
  }

  .md-x-scroll {
    overflow-x: scroll;
  }

  .md-y-auto {
    overflow-y: auto;
  }

  .md-y-hidden {
    overflow-y: hidden;
  }

  .md-y-visible {
    overflow-y: visible;
  }

  .md-y-scroll {
    overflow-y: scroll;
  }

  .md-pa-auto {
    padding: auto;
  }

  .md-pa-0 {
    padding: 0;
  }

  .md-px-0 {
    padding: 0;
  }

  .md-py-0 {
    padding: 0;
  }

  .md-pl-0 {
    padding-left: 0;
  }

  .md-pr-0 {
    padding-right: 0;
  }

  .md-pt-0 {
    padding-top: 0;
  }

  .md-pb-0 {
    padding-bottom: 0;
  }

  .md-pa-1 {
    padding: 4px;
  }

  .md-px-1 {
    padding: 0 4px;
  }

  .md-py-1 {
    padding: 4px 0;
  }

  .md-pl-1 {
    padding-left: 4px;
  }

  .md-pr-1 {
    padding-right: 4px;
  }

  .md-pt-1 {
    padding-top: 4px;
  }

  .md-pb-1 {
    padding-bottom: 4px;
  }

  .md-pa-2 {
    padding: 8px;
  }

  .md-px-2 {
    padding: 0 8px;
  }

  .md-py-2 {
    padding: 8px 0;
  }

  .md-pl-2 {
    padding-left: 8px;
  }

  .md-pr-2 {
    padding-right: 8px;
  }

  .md-pt-2 {
    padding-top: 8px;
  }

  .md-pb-2 {
    padding-bottom: 8px;
  }

  .md-pa-3 {
    padding: 12px;
  }

  .md-px-3 {
    padding: 0 12px;
  }

  .md-py-3 {
    padding: 12px 0;
  }

  .md-pl-3 {
    padding-left: 12px;
  }

  .md-pr-3 {
    padding-right: 12px;
  }

  .md-pt-3 {
    padding-top: 12px;
  }

  .md-pb-3 {
    padding-bottom: 12px;
  }

  .md-pa-4 {
    padding: 16px;
  }

  .md-px-4 {
    padding: 0 16px;
  }

  .md-py-4 {
    padding: 16px 0;
  }

  .md-pl-4 {
    padding-left: 16px;
  }

  .md-pr-4 {
    padding-right: 16px;
  }

  .md-pt-4 {
    padding-top: 16px;
  }

  .md-pb-4 {
    padding-bottom: 16px;
  }

  .md-pa-5 {
    padding: 20px;
  }

  .md-px-5 {
    padding: 0 20px;
  }

  .md-py-5 {
    padding: 20px 0;
  }

  .md-pl-5 {
    padding-left: 20px;
  }

  .md-pr-5 {
    padding-right: 20px;
  }

  .md-pt-5 {
    padding-top: 20px;
  }

  .md-pb-5 {
    padding-bottom: 20px;
  }

  .md-pa-6 {
    padding: 24px;
  }

  .md-px-6 {
    padding: 0 24px;
  }

  .md-py-6 {
    padding: 24px 0;
  }

  .md-pl-6 {
    padding-left: 24px;
  }

  .md-pr-6 {
    padding-right: 24px;
  }

  .md-pt-6 {
    padding-top: 24px;
  }

  .md-pb-6 {
    padding-bottom: 24px;
  }

  .md-pa-7 {
    padding: 28px;
  }

  .md-px-7 {
    padding: 0 28px;
  }

  .md-py-7 {
    padding: 28px 0;
  }

  .md-pl-7 {
    padding-left: 28px;
  }

  .md-pr-7 {
    padding-right: 28px;
  }

  .md-pt-7 {
    padding-top: 28px;
  }

  .md-pb-7 {
    padding-bottom: 28px;
  }

  .md-pa-8 {
    padding: 32px;
  }

  .md-px-8 {
    padding: 0 32px;
  }

  .md-py-8 {
    padding: 32px 0;
  }

  .md-pl-8 {
    padding-left: 32px;
  }

  .md-pr-8 {
    padding-right: 32px;
  }

  .md-pt-8 {
    padding-top: 32px;
  }

  .md-pb-8 {
    padding-bottom: 32px;
  }

  .md-pa-9 {
    padding: 36px;
  }

  .md-px-9 {
    padding: 0 36px;
  }

  .md-py-9 {
    padding: 36px 0;
  }

  .md-pl-9 {
    padding-left: 36px;
  }

  .md-pr-9 {
    padding-right: 36px;
  }

  .md-pt-9 {
    padding-top: 36px;
  }

  .md-pb-9 {
    padding-bottom: 36px;
  }

  .md-pa-10 {
    padding: 40px;
  }

  .md-px-10 {
    padding: 0 40px;
  }

  .md-py-10 {
    padding: 40px 0;
  }

  .md-pl-10 {
    padding-left: 40px;
  }

  .md-pr-10 {
    padding-right: 40px;
  }

  .md-pt-10 {
    padding-top: 40px;
  }

  .md-pb-10 {
    padding-bottom: 40px;
  }

  .md-pa-11 {
    padding: 44px;
  }

  .md-px-11 {
    padding: 0 44px;
  }

  .md-py-11 {
    padding: 44px 0;
  }

  .md-pl-11 {
    padding-left: 44px;
  }

  .md-pr-11 {
    padding-right: 44px;
  }

  .md-pt-11 {
    padding-top: 44px;
  }

  .md-pb-11 {
    padding-bottom: 44px;
  }

  .md-pa-12 {
    padding: 48px;
  }

  .md-px-12 {
    padding: 0 48px;
  }

  .md-py-12 {
    padding: 48px 0;
  }

  .md-pl-12 {
    padding-left: 48px;
  }

  .md-pr-12 {
    padding-right: 48px;
  }

  .md-pt-12 {
    padding-top: 48px;
  }

  .md-pb-12 {
    padding-bottom: 48px;
  }

  .md-pa-13 {
    padding: 52px;
  }

  .md-px-13 {
    padding: 0 52px;
  }

  .md-py-13 {
    padding: 52px 0;
  }

  .md-pl-13 {
    padding-left: 52px;
  }

  .md-pr-13 {
    padding-right: 52px;
  }

  .md-pt-13 {
    padding-top: 52px;
  }

  .md-pb-13 {
    padding-bottom: 52px;
  }

  .md-pa-14 {
    padding: 56px;
  }

  .md-px-14 {
    padding: 0 56px;
  }

  .md-py-14 {
    padding: 56px 0;
  }

  .md-pl-14 {
    padding-left: 56px;
  }

  .md-pr-14 {
    padding-right: 56px;
  }

  .md-pt-14 {
    padding-top: 56px;
  }

  .md-pb-14 {
    padding-bottom: 56px;
  }

  .md-pa-15 {
    padding: 60px;
  }

  .md-px-15 {
    padding: 0 60px;
  }

  .md-py-15 {
    padding: 60px 0;
  }

  .md-pl-15 {
    padding-left: 60px;
  }

  .md-pr-15 {
    padding-right: 60px;
  }

  .md-pt-15 {
    padding-top: 60px;
  }

  .md-pb-15 {
    padding-bottom: 60px;
  }

  .md-pa-16 {
    padding: 64px;
  }

  .md-px-16 {
    padding: 0 64px;
  }

  .md-py-16 {
    padding: 64px 0;
  }

  .md-pl-16 {
    padding-left: 64px;
  }

  .md-pr-16 {
    padding-right: 64px;
  }

  .md-pt-16 {
    padding-top: 64px;
  }

  .md-pb-16 {
    padding-bottom: 64px;
  }

  .md-static {
    position: static;
  }

  .md-fixed {
    position: fixed;
  }

  .md-absolute {
    position: absolute;
  }

  .md-relative {
    position: relative;
  }

  .md-sticky {
    position: sticky;
  }

  .md-top-0 {
    top: 0;
  }

  .md-top-1 {
    top: 2px;
  }

  .md-top-2 {
    top: 4px;
  }

  .md-top-3 {
    top: 6px;
  }

  .md-top-4 {
    top: 8px;
  }

  .md-top-5 {
    top: 10px;
  }

  .md-top-6 {
    top: 12px;
  }

  .md-top-7 {
    top: 14px;
  }

  .md-top-8 {
    top: 16px;
  }

  .md-top-9 {
    top: 18px;
  }

  .md-top-10 {
    top: 20px;
  }

  .md-top-11 {
    top: 22px;
  }

  .md-top-12 {
    top: 24px;
  }

  .md-top-13 {
    top: 26px;
  }

  .md-top-14 {
    top: 28px;
  }

  .md-top-15 {
    top: 30px;
  }

  .md-top-16 {
    top: 32px;
  }

  .md-bottom-0 {
    bottom: 0;
  }

  .md-bottom-1 {
    bottom: 2px;
  }

  .md-bottom-2 {
    bottom: 4px;
  }

  .md-bottom-3 {
    bottom: 6px;
  }

  .md-bottom-4 {
    bottom: 8px;
  }

  .md-bottom-5 {
    bottom: 10px;
  }

  .md-bottom-6 {
    bottom: 12px;
  }

  .md-bottom-7 {
    bottom: 14px;
  }

  .md-bottom-8 {
    bottom: 16px;
  }

  .md-bottom-9 {
    bottom: 18px;
  }

  .md-bottom-10 {
    bottom: 20px;
  }

  .md-bottom-11 {
    bottom: 22px;
  }

  .md-bottom-12 {
    bottom: 24px;
  }

  .md-bottom-13 {
    bottom: 26px;
  }

  .md-bottom-14 {
    bottom: 28px;
  }

  .md-bottom-15 {
    bottom: 30px;
  }

  .md-bottom-16 {
    bottom: 32px;
  }

  .md-left-0 {
    left: 0;
  }

  .md-left-1 {
    left: 2px;
  }

  .md-left-2 {
    left: 4px;
  }

  .md-left-3 {
    left: 6px;
  }

  .md-left-4 {
    left: 8px;
  }

  .md-left-5 {
    left: 10px;
  }

  .md-left-6 {
    left: 12px;
  }

  .md-left-7 {
    left: 14px;
  }

  .md-left-8 {
    left: 16px;
  }

  .md-left-9 {
    left: 18px;
  }

  .md-left-10 {
    left: 20px;
  }

  .md-left-11 {
    left: 22px;
  }

  .md-left-12 {
    left: 24px;
  }

  .md-left-13 {
    left: 26px;
  }

  .md-left-14 {
    left: 28px;
  }

  .md-left-15 {
    left: 30px;
  }

  .md-left-16 {
    left: 32px;
  }

  .md-right-0 {
    right: 0;
  }

  .md-right-1 {
    right: 2px;
  }

  .md-right-2 {
    right: 4px;
  }

  .md-right-3 {
    right: 6px;
  }

  .md-right-4 {
    right: 8px;
  }

  .md-right-5 {
    right: 10px;
  }

  .md-right-6 {
    right: 12px;
  }

  .md-right-7 {
    right: 14px;
  }

  .md-right-8 {
    right: 16px;
  }

  .md-right-9 {
    right: 18px;
  }

  .md-right-10 {
    right: 20px;
  }

  .md-right-11 {
    right: 22px;
  }

  .md-right-12 {
    right: 24px;
  }

  .md-right-13 {
    right: 26px;
  }

  .md-right-14 {
    right: 28px;
  }

  .md-right-15 {
    right: 30px;
  }

  .md-right-16 {
    right: 32px;
  }

  .md-z-index-0 {
    z-index: 0;
  }

  .md-z-index-1 {
    z-index: 1;
  }

  .md-z-index-2 {
    z-index: 2;
  }

  .md-z-index-3 {
    z-index: 3;
  }

  .md-z-index-auto {
    z-index: auto;
  }

  .md-z-index-top {
    z-index: 99999;
  }

  .md-z-index-bottom {
    z-index: -99999;
  }

  .md-row {
    display: flex;
    flex-wrap: wrap;
  }

  .md-col-1 {
    flex: 0 0 8.3333%;
    max-width: 8.3333%;
  }

  .md-col-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .md-col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .md-col-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .md-col-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .md-col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .md-col-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .md-col-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .md-col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .md-col-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .md-col-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .md-col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .md-h-auto {
    height: auto;
  }

  .md-h-full {
    height: 100%;
  }

  .md-h-screen {
    height: 100vh;
  }

  .md-w-auto {
    width: auto;
  }

  .md-w-full {
    width: 100%;
  }

  .md-w-screen {
    width: 100vw;
  }

  .md-text-center {
    text-align: center;
  }

  .md-text-left {
    text-align: left;
  }

  .md-text-right {
    text-align: right;
  }

  .md-font-bold {
    font-weight: bold;
  }

  .md-font-normal {
    font-weight: normal;
  }

  .md-font-light {
    font-weight: light;
  }

  .md-font-italic {
    font-style: italic;
  }

  .md-font-underline {
    text-decoration: underline;
  }

  .md-font-overline {
    text-decoration: overline;
  }

  .md-font-line-through {
    text-decoration: line-through;
  }
}

@media (width <= 992px) {
  .lg-visible {
    display: block;
  }

  .lg-inline {
    display: inline;
  }

  .lg-inline-block {
    display: inline-block;
  }

  .lg-flex {
    display: flex;
  }

  .lg-inline-flex {
    display: inline-flex;
  }

  .lg-grid {
    display: grid;
  }

  .lg-inline-grid {
    display: inline-grid;
  }

  .lg-hidden {
    display: none;
  }

  .lg-flex-row {
    flex-direction: row;
  }

  .lg-flex-col {
    flex-direction: column;
  }

  .lg-flex-row-reverse {
    flex-direction: row-reverse;
  }

  .lg-flex-col-reverse {
    flex-direction: column-reverse;
  }

  .lg-flex-grow-0 {
    flex-grow: 0;
  }

  .lg-flex-grow-1 {
    flex-grow: 1;
  }

  .lg-flex-grow-2 {
    flex-grow: 2;
  }

  .lg-flex-grow-3 {
    flex-grow: 3;
  }

  .lg-flex-wrap {
    flex-wrap: wrap;
  }

  .lg-flex-nowrap {
    flex-wrap: nowrap;
  }

  .lg-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .lg-justify-start {
    justify-content: flex-start;
  }

  .lg-justify-end {
    justify-content: flex-end;
  }

  .lg-justify-center {
    justify-content: center;
  }

  .lg-justify-between {
    justify-content: space-between;
  }

  .lg-justify-around {
    justify-content: space-around;
  }

  .lg-justify-evenly {
    justify-content: space-evenly;
  }

  .lg-items-start {
    align-items: flex-start;
  }

  .lg-items-end {
    align-items: flex-end;
  }

  .lg-items-center {
    align-items: center;
  }

  .lg-items-baseline {
    align-items: baseline;
  }

  .lg-items-stretch {
    align-items: stretch;
  }

  .lg-content-start {
    align-content: flex-start;
  }

  .lg-content-end {
    align-content: flex-end;
  }

  .lg-content-center {
    align-content: center;
  }

  .lg-content-between {
    align-content: space-between;
  }

  .lg-content-around {
    align-content: space-around;
  }

  .lg-content-stretch {
    align-content: stretch;
  }

  .lg-self-auto {
    align-self: auto;
  }

  .lg-self-start {
    align-self: flex-start;
  }

  .lg-self-end {
    align-self: flex-end;
  }

  .lg-self-center {
    align-self: center;
  }

  .lg-self-stretch {
    align-self: stretch;
  }

  .lg-gap-1 {
    gap: 2;
  }

  .lg-gap-2 {
    gap: 4;
  }

  .lg-gap-3 {
    gap: 6;
  }

  .lg-gap-4 {
    gap: 8;
  }

  .lg-gap-5 {
    gap: 10;
  }

  .lg-gap-6 {
    gap: 12;
  }

  .lg-gap-7 {
    gap: 14;
  }

  .lg-gap-8 {
    gap: 16;
  }

  .lg-gap-9 {
    gap: 18;
  }

  .lg-gap-10 {
    gap: 20;
  }

  .lg-gap-11 {
    gap: 22;
  }

  .lg-gap-12 {
    gap: 24;
  }

  .lg-gap-13 {
    gap: 26;
  }

  .lg-gap-14 {
    gap: 28;
  }

  .lg-gap-15 {
    gap: 30;
  }

  .lg-gap-16 {
    gap: 32;
  }

  .lg-float-left {
    float: left;
  }

  .lg-float-right {
    float: right;
  }

  .lg-ma-auto {
    margin: auto;
  }

  .lg-ma-0 {
    margin: 0;
  }

  .lg-mx-0 {
    margin: 0;
  }

  .lg-my-0 {
    margin: 0;
  }

  .lg-ml-0 {
    margin-left: 0;
  }

  .lg-mr-0 {
    margin-right: 0;
  }

  .lg-mt-0 {
    margin-top: 0;
  }

  .lg-mb-0 {
    margin-bottom: 0;
  }

  .lg-ma-1 {
    margin: 4px;
  }

  .lg-mx-1 {
    margin: 0 4px;
  }

  .lg-my-1 {
    margin: 4px 0;
  }

  .lg-ml-1 {
    margin-left: 4px;
  }

  .lg-mr-1 {
    margin-right: 4px;
  }

  .lg-mt-1 {
    margin-top: 4px;
  }

  .lg-mb-1 {
    margin-bottom: 4px;
  }

  .lg-ma-2 {
    margin: 8px;
  }

  .lg-mx-2 {
    margin: 0 8px;
  }

  .lg-my-2 {
    margin: 8px 0;
  }

  .lg-ml-2 {
    margin-left: 8px;
  }

  .lg-mr-2 {
    margin-right: 8px;
  }

  .lg-mt-2 {
    margin-top: 8px;
  }

  .lg-mb-2 {
    margin-bottom: 8px;
  }

  .lg-ma-3 {
    margin: 12px;
  }

  .lg-mx-3 {
    margin: 0 12px;
  }

  .lg-my-3 {
    margin: 12px 0;
  }

  .lg-ml-3 {
    margin-left: 12px;
  }

  .lg-mr-3 {
    margin-right: 12px;
  }

  .lg-mt-3 {
    margin-top: 12px;
  }

  .lg-mb-3 {
    margin-bottom: 12px;
  }

  .lg-ma-4 {
    margin: 16px;
  }

  .lg-mx-4 {
    margin: 0 16px;
  }

  .lg-my-4 {
    margin: 16px 0;
  }

  .lg-ml-4 {
    margin-left: 16px;
  }

  .lg-mr-4 {
    margin-right: 16px;
  }

  .lg-mt-4 {
    margin-top: 16px;
  }

  .lg-mb-4 {
    margin-bottom: 16px;
  }

  .lg-ma-5 {
    margin: 20px;
  }

  .lg-mx-5 {
    margin: 0 20px;
  }

  .lg-my-5 {
    margin: 20px 0;
  }

  .lg-ml-5 {
    margin-left: 20px;
  }

  .lg-mr-5 {
    margin-right: 20px;
  }

  .lg-mt-5 {
    margin-top: 20px;
  }

  .lg-mb-5 {
    margin-bottom: 20px;
  }

  .lg-ma-6 {
    margin: 24px;
  }

  .lg-mx-6 {
    margin: 0 24px;
  }

  .lg-my-6 {
    margin: 24px 0;
  }

  .lg-ml-6 {
    margin-left: 24px;
  }

  .lg-mr-6 {
    margin-right: 24px;
  }

  .lg-mt-6 {
    margin-top: 24px;
  }

  .lg-mb-6 {
    margin-bottom: 24px;
  }

  .lg-ma-7 {
    margin: 28px;
  }

  .lg-mx-7 {
    margin: 0 28px;
  }

  .lg-my-7 {
    margin: 28px 0;
  }

  .lg-ml-7 {
    margin-left: 28px;
  }

  .lg-mr-7 {
    margin-right: 28px;
  }

  .lg-mt-7 {
    margin-top: 28px;
  }

  .lg-mb-7 {
    margin-bottom: 28px;
  }

  .lg-ma-8 {
    margin: 32px;
  }

  .lg-mx-8 {
    margin: 0 32px;
  }

  .lg-my-8 {
    margin: 32px 0;
  }

  .lg-ml-8 {
    margin-left: 32px;
  }

  .lg-mr-8 {
    margin-right: 32px;
  }

  .lg-mt-8 {
    margin-top: 32px;
  }

  .lg-mb-8 {
    margin-bottom: 32px;
  }

  .lg-ma-9 {
    margin: 36px;
  }

  .lg-mx-9 {
    margin: 0 36px;
  }

  .lg-my-9 {
    margin: 36px 0;
  }

  .lg-ml-9 {
    margin-left: 36px;
  }

  .lg-mr-9 {
    margin-right: 36px;
  }

  .lg-mt-9 {
    margin-top: 36px;
  }

  .lg-mb-9 {
    margin-bottom: 36px;
  }

  .lg-ma-10 {
    margin: 40px;
  }

  .lg-mx-10 {
    margin: 0 40px;
  }

  .lg-my-10 {
    margin: 40px 0;
  }

  .lg-ml-10 {
    margin-left: 40px;
  }

  .lg-mr-10 {
    margin-right: 40px;
  }

  .lg-mt-10 {
    margin-top: 40px;
  }

  .lg-mb-10 {
    margin-bottom: 40px;
  }

  .lg-ma-11 {
    margin: 44px;
  }

  .lg-mx-11 {
    margin: 0 44px;
  }

  .lg-my-11 {
    margin: 44px 0;
  }

  .lg-ml-11 {
    margin-left: 44px;
  }

  .lg-mr-11 {
    margin-right: 44px;
  }

  .lg-mt-11 {
    margin-top: 44px;
  }

  .lg-mb-11 {
    margin-bottom: 44px;
  }

  .lg-ma-12 {
    margin: 48px;
  }

  .lg-mx-12 {
    margin: 0 48px;
  }

  .lg-my-12 {
    margin: 48px 0;
  }

  .lg-ml-12 {
    margin-left: 48px;
  }

  .lg-mr-12 {
    margin-right: 48px;
  }

  .lg-mt-12 {
    margin-top: 48px;
  }

  .lg-mb-12 {
    margin-bottom: 48px;
  }

  .lg-ma-13 {
    margin: 52px;
  }

  .lg-mx-13 {
    margin: 0 52px;
  }

  .lg-my-13 {
    margin: 52px 0;
  }

  .lg-ml-13 {
    margin-left: 52px;
  }

  .lg-mr-13 {
    margin-right: 52px;
  }

  .lg-mt-13 {
    margin-top: 52px;
  }

  .lg-mb-13 {
    margin-bottom: 52px;
  }

  .lg-ma-14 {
    margin: 56px;
  }

  .lg-mx-14 {
    margin: 0 56px;
  }

  .lg-my-14 {
    margin: 56px 0;
  }

  .lg-ml-14 {
    margin-left: 56px;
  }

  .lg-mr-14 {
    margin-right: 56px;
  }

  .lg-mt-14 {
    margin-top: 56px;
  }

  .lg-mb-14 {
    margin-bottom: 56px;
  }

  .lg-ma-15 {
    margin: 60px;
  }

  .lg-mx-15 {
    margin: 0 60px;
  }

  .lg-my-15 {
    margin: 60px 0;
  }

  .lg-ml-15 {
    margin-left: 60px;
  }

  .lg-mr-15 {
    margin-right: 60px;
  }

  .lg-mt-15 {
    margin-top: 60px;
  }

  .lg-mb-15 {
    margin-bottom: 60px;
  }

  .lg-ma-16 {
    margin: 64px;
  }

  .lg-mx-16 {
    margin: 0 64px;
  }

  .lg-my-16 {
    margin: 64px 0;
  }

  .lg-ml-16 {
    margin-left: 64px;
  }

  .lg-mr-16 {
    margin-right: 64px;
  }

  .lg-mt-16 {
    margin-top: 64px;
  }

  .lg-mb-16 {
    margin-bottom: 64px;
  }

  .lg-overflow {
    overflow: auto;
  }

  .lg-overflow-hidden {
    overflow: hidden;
  }

  .lg-overflow-visible {
    overflow: visible;
  }

  .lg-overflow-scroll {
    overflow: scroll;
  }

  .lg-x-auto {
    overflow-x: auto;
  }

  .lg-x-hidden {
    overflow-x: hidden;
  }

  .lg-x-visible {
    overflow-x: visible;
  }

  .lg-x-scroll {
    overflow-x: scroll;
  }

  .lg-y-auto {
    overflow-y: auto;
  }

  .lg-y-hidden {
    overflow-y: hidden;
  }

  .lg-y-visible {
    overflow-y: visible;
  }

  .lg-y-scroll {
    overflow-y: scroll;
  }

  .lg-pa-auto {
    padding: auto;
  }

  .lg-pa-0 {
    padding: 0;
  }

  .lg-px-0 {
    padding: 0;
  }

  .lg-py-0 {
    padding: 0;
  }

  .lg-pl-0 {
    padding-left: 0;
  }

  .lg-pr-0 {
    padding-right: 0;
  }

  .lg-pt-0 {
    padding-top: 0;
  }

  .lg-pb-0 {
    padding-bottom: 0;
  }

  .lg-pa-1 {
    padding: 4px;
  }

  .lg-px-1 {
    padding: 0 4px;
  }

  .lg-py-1 {
    padding: 4px 0;
  }

  .lg-pl-1 {
    padding-left: 4px;
  }

  .lg-pr-1 {
    padding-right: 4px;
  }

  .lg-pt-1 {
    padding-top: 4px;
  }

  .lg-pb-1 {
    padding-bottom: 4px;
  }

  .lg-pa-2 {
    padding: 8px;
  }

  .lg-px-2 {
    padding: 0 8px;
  }

  .lg-py-2 {
    padding: 8px 0;
  }

  .lg-pl-2 {
    padding-left: 8px;
  }

  .lg-pr-2 {
    padding-right: 8px;
  }

  .lg-pt-2 {
    padding-top: 8px;
  }

  .lg-pb-2 {
    padding-bottom: 8px;
  }

  .lg-pa-3 {
    padding: 12px;
  }

  .lg-px-3 {
    padding: 0 12px;
  }

  .lg-py-3 {
    padding: 12px 0;
  }

  .lg-pl-3 {
    padding-left: 12px;
  }

  .lg-pr-3 {
    padding-right: 12px;
  }

  .lg-pt-3 {
    padding-top: 12px;
  }

  .lg-pb-3 {
    padding-bottom: 12px;
  }

  .lg-pa-4 {
    padding: 16px;
  }

  .lg-px-4 {
    padding: 0 16px;
  }

  .lg-py-4 {
    padding: 16px 0;
  }

  .lg-pl-4 {
    padding-left: 16px;
  }

  .lg-pr-4 {
    padding-right: 16px;
  }

  .lg-pt-4 {
    padding-top: 16px;
  }

  .lg-pb-4 {
    padding-bottom: 16px;
  }

  .lg-pa-5 {
    padding: 20px;
  }

  .lg-px-5 {
    padding: 0 20px;
  }

  .lg-py-5 {
    padding: 20px 0;
  }

  .lg-pl-5 {
    padding-left: 20px;
  }

  .lg-pr-5 {
    padding-right: 20px;
  }

  .lg-pt-5 {
    padding-top: 20px;
  }

  .lg-pb-5 {
    padding-bottom: 20px;
  }

  .lg-pa-6 {
    padding: 24px;
  }

  .lg-px-6 {
    padding: 0 24px;
  }

  .lg-py-6 {
    padding: 24px 0;
  }

  .lg-pl-6 {
    padding-left: 24px;
  }

  .lg-pr-6 {
    padding-right: 24px;
  }

  .lg-pt-6 {
    padding-top: 24px;
  }

  .lg-pb-6 {
    padding-bottom: 24px;
  }

  .lg-pa-7 {
    padding: 28px;
  }

  .lg-px-7 {
    padding: 0 28px;
  }

  .lg-py-7 {
    padding: 28px 0;
  }

  .lg-pl-7 {
    padding-left: 28px;
  }

  .lg-pr-7 {
    padding-right: 28px;
  }

  .lg-pt-7 {
    padding-top: 28px;
  }

  .lg-pb-7 {
    padding-bottom: 28px;
  }

  .lg-pa-8 {
    padding: 32px;
  }

  .lg-px-8 {
    padding: 0 32px;
  }

  .lg-py-8 {
    padding: 32px 0;
  }

  .lg-pl-8 {
    padding-left: 32px;
  }

  .lg-pr-8 {
    padding-right: 32px;
  }

  .lg-pt-8 {
    padding-top: 32px;
  }

  .lg-pb-8 {
    padding-bottom: 32px;
  }

  .lg-pa-9 {
    padding: 36px;
  }

  .lg-px-9 {
    padding: 0 36px;
  }

  .lg-py-9 {
    padding: 36px 0;
  }

  .lg-pl-9 {
    padding-left: 36px;
  }

  .lg-pr-9 {
    padding-right: 36px;
  }

  .lg-pt-9 {
    padding-top: 36px;
  }

  .lg-pb-9 {
    padding-bottom: 36px;
  }

  .lg-pa-10 {
    padding: 40px;
  }

  .lg-px-10 {
    padding: 0 40px;
  }

  .lg-py-10 {
    padding: 40px 0;
  }

  .lg-pl-10 {
    padding-left: 40px;
  }

  .lg-pr-10 {
    padding-right: 40px;
  }

  .lg-pt-10 {
    padding-top: 40px;
  }

  .lg-pb-10 {
    padding-bottom: 40px;
  }

  .lg-pa-11 {
    padding: 44px;
  }

  .lg-px-11 {
    padding: 0 44px;
  }

  .lg-py-11 {
    padding: 44px 0;
  }

  .lg-pl-11 {
    padding-left: 44px;
  }

  .lg-pr-11 {
    padding-right: 44px;
  }

  .lg-pt-11 {
    padding-top: 44px;
  }

  .lg-pb-11 {
    padding-bottom: 44px;
  }

  .lg-pa-12 {
    padding: 48px;
  }

  .lg-px-12 {
    padding: 0 48px;
  }

  .lg-py-12 {
    padding: 48px 0;
  }

  .lg-pl-12 {
    padding-left: 48px;
  }

  .lg-pr-12 {
    padding-right: 48px;
  }

  .lg-pt-12 {
    padding-top: 48px;
  }

  .lg-pb-12 {
    padding-bottom: 48px;
  }

  .lg-pa-13 {
    padding: 52px;
  }

  .lg-px-13 {
    padding: 0 52px;
  }

  .lg-py-13 {
    padding: 52px 0;
  }

  .lg-pl-13 {
    padding-left: 52px;
  }

  .lg-pr-13 {
    padding-right: 52px;
  }

  .lg-pt-13 {
    padding-top: 52px;
  }

  .lg-pb-13 {
    padding-bottom: 52px;
  }

  .lg-pa-14 {
    padding: 56px;
  }

  .lg-px-14 {
    padding: 0 56px;
  }

  .lg-py-14 {
    padding: 56px 0;
  }

  .lg-pl-14 {
    padding-left: 56px;
  }

  .lg-pr-14 {
    padding-right: 56px;
  }

  .lg-pt-14 {
    padding-top: 56px;
  }

  .lg-pb-14 {
    padding-bottom: 56px;
  }

  .lg-pa-15 {
    padding: 60px;
  }

  .lg-px-15 {
    padding: 0 60px;
  }

  .lg-py-15 {
    padding: 60px 0;
  }

  .lg-pl-15 {
    padding-left: 60px;
  }

  .lg-pr-15 {
    padding-right: 60px;
  }

  .lg-pt-15 {
    padding-top: 60px;
  }

  .lg-pb-15 {
    padding-bottom: 60px;
  }

  .lg-pa-16 {
    padding: 64px;
  }

  .lg-px-16 {
    padding: 0 64px;
  }

  .lg-py-16 {
    padding: 64px 0;
  }

  .lg-pl-16 {
    padding-left: 64px;
  }

  .lg-pr-16 {
    padding-right: 64px;
  }

  .lg-pt-16 {
    padding-top: 64px;
  }

  .lg-pb-16 {
    padding-bottom: 64px;
  }

  .lg-static {
    position: static;
  }

  .lg-fixed {
    position: fixed;
  }

  .lg-absolute {
    position: absolute;
  }

  .lg-relative {
    position: relative;
  }

  .lg-sticky {
    position: sticky;
  }

  .lg-top-0 {
    top: 0;
  }

  .lg-top-1 {
    top: 2px;
  }

  .lg-top-2 {
    top: 4px;
  }

  .lg-top-3 {
    top: 6px;
  }

  .lg-top-4 {
    top: 8px;
  }

  .lg-top-5 {
    top: 10px;
  }

  .lg-top-6 {
    top: 12px;
  }

  .lg-top-7 {
    top: 14px;
  }

  .lg-top-8 {
    top: 16px;
  }

  .lg-top-9 {
    top: 18px;
  }

  .lg-top-10 {
    top: 20px;
  }

  .lg-top-11 {
    top: 22px;
  }

  .lg-top-12 {
    top: 24px;
  }

  .lg-top-13 {
    top: 26px;
  }

  .lg-top-14 {
    top: 28px;
  }

  .lg-top-15 {
    top: 30px;
  }

  .lg-top-16 {
    top: 32px;
  }

  .lg-bottom-0 {
    bottom: 0;
  }

  .lg-bottom-1 {
    bottom: 2px;
  }

  .lg-bottom-2 {
    bottom: 4px;
  }

  .lg-bottom-3 {
    bottom: 6px;
  }

  .lg-bottom-4 {
    bottom: 8px;
  }

  .lg-bottom-5 {
    bottom: 10px;
  }

  .lg-bottom-6 {
    bottom: 12px;
  }

  .lg-bottom-7 {
    bottom: 14px;
  }

  .lg-bottom-8 {
    bottom: 16px;
  }

  .lg-bottom-9 {
    bottom: 18px;
  }

  .lg-bottom-10 {
    bottom: 20px;
  }

  .lg-bottom-11 {
    bottom: 22px;
  }

  .lg-bottom-12 {
    bottom: 24px;
  }

  .lg-bottom-13 {
    bottom: 26px;
  }

  .lg-bottom-14 {
    bottom: 28px;
  }

  .lg-bottom-15 {
    bottom: 30px;
  }

  .lg-bottom-16 {
    bottom: 32px;
  }

  .lg-left-0 {
    left: 0;
  }

  .lg-left-1 {
    left: 2px;
  }

  .lg-left-2 {
    left: 4px;
  }

  .lg-left-3 {
    left: 6px;
  }

  .lg-left-4 {
    left: 8px;
  }

  .lg-left-5 {
    left: 10px;
  }

  .lg-left-6 {
    left: 12px;
  }

  .lg-left-7 {
    left: 14px;
  }

  .lg-left-8 {
    left: 16px;
  }

  .lg-left-9 {
    left: 18px;
  }

  .lg-left-10 {
    left: 20px;
  }

  .lg-left-11 {
    left: 22px;
  }

  .lg-left-12 {
    left: 24px;
  }

  .lg-left-13 {
    left: 26px;
  }

  .lg-left-14 {
    left: 28px;
  }

  .lg-left-15 {
    left: 30px;
  }

  .lg-left-16 {
    left: 32px;
  }

  .lg-right-0 {
    right: 0;
  }

  .lg-right-1 {
    right: 2px;
  }

  .lg-right-2 {
    right: 4px;
  }

  .lg-right-3 {
    right: 6px;
  }

  .lg-right-4 {
    right: 8px;
  }

  .lg-right-5 {
    right: 10px;
  }

  .lg-right-6 {
    right: 12px;
  }

  .lg-right-7 {
    right: 14px;
  }

  .lg-right-8 {
    right: 16px;
  }

  .lg-right-9 {
    right: 18px;
  }

  .lg-right-10 {
    right: 20px;
  }

  .lg-right-11 {
    right: 22px;
  }

  .lg-right-12 {
    right: 24px;
  }

  .lg-right-13 {
    right: 26px;
  }

  .lg-right-14 {
    right: 28px;
  }

  .lg-right-15 {
    right: 30px;
  }

  .lg-right-16 {
    right: 32px;
  }

  .lg-z-index-0 {
    z-index: 0;
  }

  .lg-z-index-1 {
    z-index: 1;
  }

  .lg-z-index-2 {
    z-index: 2;
  }

  .lg-z-index-3 {
    z-index: 3;
  }

  .lg-z-index-auto {
    z-index: auto;
  }

  .lg-z-index-top {
    z-index: 99999;
  }

  .lg-z-index-bottom {
    z-index: -99999;
  }

  .lg-row {
    display: flex;
    flex-wrap: wrap;
  }

  .lg-col-1 {
    flex: 0 0 8.3333%;
    max-width: 8.3333%;
  }

  .lg-col-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .lg-col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .lg-col-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .lg-col-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .lg-col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .lg-col-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .lg-col-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .lg-col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .lg-col-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .lg-col-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .lg-col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .lg-h-auto {
    height: auto;
  }

  .lg-h-full {
    height: 100%;
  }

  .lg-h-screen {
    height: 100vh;
  }

  .lg-w-auto {
    width: auto;
  }

  .lg-w-full {
    width: 100%;
  }

  .lg-w-screen {
    width: 100vw;
  }

  .lg-text-center {
    text-align: center;
  }

  .lg-text-left {
    text-align: left;
  }

  .lg-text-right {
    text-align: right;
  }

  .lg-font-bold {
    font-weight: bold;
  }

  .lg-font-normal {
    font-weight: normal;
  }

  .lg-font-light {
    font-weight: light;
  }

  .lg-font-italic {
    font-style: italic;
  }

  .lg-font-underline {
    text-decoration: underline;
  }

  .lg-font-overline {
    text-decoration: overline;
  }

  .lg-font-line-through {
    text-decoration: line-through;
  }
}

@media (width <= 1200px) {
  .xl-visible {
    display: block;
  }

  .xl-inline {
    display: inline;
  }

  .xl-inline-block {
    display: inline-block;
  }

  .xl-flex {
    display: flex;
  }

  .xl-inline-flex {
    display: inline-flex;
  }

  .xl-grid {
    display: grid;
  }

  .xl-inline-grid {
    display: inline-grid;
  }

  .xl-hidden {
    display: none;
  }

  .xl-flex-row {
    flex-direction: row;
  }

  .xl-flex-col {
    flex-direction: column;
  }

  .xl-flex-row-reverse {
    flex-direction: row-reverse;
  }

  .xl-flex-col-reverse {
    flex-direction: column-reverse;
  }

  .xl-flex-grow-0 {
    flex-grow: 0;
  }

  .xl-flex-grow-1 {
    flex-grow: 1;
  }

  .xl-flex-grow-2 {
    flex-grow: 2;
  }

  .xl-flex-grow-3 {
    flex-grow: 3;
  }

  .xl-flex-wrap {
    flex-wrap: wrap;
  }

  .xl-flex-nowrap {
    flex-wrap: nowrap;
  }

  .xl-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }

  .xl-justify-start {
    justify-content: flex-start;
  }

  .xl-justify-end {
    justify-content: flex-end;
  }

  .xl-justify-center {
    justify-content: center;
  }

  .xl-justify-between {
    justify-content: space-between;
  }

  .xl-justify-around {
    justify-content: space-around;
  }

  .xl-justify-evenly {
    justify-content: space-evenly;
  }

  .xl-items-start {
    align-items: flex-start;
  }

  .xl-items-end {
    align-items: flex-end;
  }

  .xl-items-center {
    align-items: center;
  }

  .xl-items-baseline {
    align-items: baseline;
  }

  .xl-items-stretch {
    align-items: stretch;
  }

  .xl-content-start {
    align-content: flex-start;
  }

  .xl-content-end {
    align-content: flex-end;
  }

  .xl-content-center {
    align-content: center;
  }

  .xl-content-between {
    align-content: space-between;
  }

  .xl-content-around {
    align-content: space-around;
  }

  .xl-content-stretch {
    align-content: stretch;
  }

  .xl-self-auto {
    align-self: auto;
  }

  .xl-self-start {
    align-self: flex-start;
  }

  .xl-self-end {
    align-self: flex-end;
  }

  .xl-self-center {
    align-self: center;
  }

  .xl-self-stretch {
    align-self: stretch;
  }

  .xl-gap-1 {
    gap: 2;
  }

  .xl-gap-2 {
    gap: 4;
  }

  .xl-gap-3 {
    gap: 6;
  }

  .xl-gap-4 {
    gap: 8;
  }

  .xl-gap-5 {
    gap: 10;
  }

  .xl-gap-6 {
    gap: 12;
  }

  .xl-gap-7 {
    gap: 14;
  }

  .xl-gap-8 {
    gap: 16;
  }

  .xl-gap-9 {
    gap: 18;
  }

  .xl-gap-10 {
    gap: 20;
  }

  .xl-gap-11 {
    gap: 22;
  }

  .xl-gap-12 {
    gap: 24;
  }

  .xl-gap-13 {
    gap: 26;
  }

  .xl-gap-14 {
    gap: 28;
  }

  .xl-gap-15 {
    gap: 30;
  }

  .xl-gap-16 {
    gap: 32;
  }

  .xl-float-left {
    float: left;
  }

  .xl-float-right {
    float: right;
  }

  .xl-ma-auto {
    margin: auto;
  }

  .xl-ma-0 {
    margin: 0;
  }

  .xl-mx-0 {
    margin: 0;
  }

  .xl-my-0 {
    margin: 0;
  }

  .xl-ml-0 {
    margin-left: 0;
  }

  .xl-mr-0 {
    margin-right: 0;
  }

  .xl-mt-0 {
    margin-top: 0;
  }

  .xl-mb-0 {
    margin-bottom: 0;
  }

  .xl-ma-1 {
    margin: 4px;
  }

  .xl-mx-1 {
    margin: 0 4px;
  }

  .xl-my-1 {
    margin: 4px 0;
  }

  .xl-ml-1 {
    margin-left: 4px;
  }

  .xl-mr-1 {
    margin-right: 4px;
  }

  .xl-mt-1 {
    margin-top: 4px;
  }

  .xl-mb-1 {
    margin-bottom: 4px;
  }

  .xl-ma-2 {
    margin: 8px;
  }

  .xl-mx-2 {
    margin: 0 8px;
  }

  .xl-my-2 {
    margin: 8px 0;
  }

  .xl-ml-2 {
    margin-left: 8px;
  }

  .xl-mr-2 {
    margin-right: 8px;
  }

  .xl-mt-2 {
    margin-top: 8px;
  }

  .xl-mb-2 {
    margin-bottom: 8px;
  }

  .xl-ma-3 {
    margin: 12px;
  }

  .xl-mx-3 {
    margin: 0 12px;
  }

  .xl-my-3 {
    margin: 12px 0;
  }

  .xl-ml-3 {
    margin-left: 12px;
  }

  .xl-mr-3 {
    margin-right: 12px;
  }

  .xl-mt-3 {
    margin-top: 12px;
  }

  .xl-mb-3 {
    margin-bottom: 12px;
  }

  .xl-ma-4 {
    margin: 16px;
  }

  .xl-mx-4 {
    margin: 0 16px;
  }

  .xl-my-4 {
    margin: 16px 0;
  }

  .xl-ml-4 {
    margin-left: 16px;
  }

  .xl-mr-4 {
    margin-right: 16px;
  }

  .xl-mt-4 {
    margin-top: 16px;
  }

  .xl-mb-4 {
    margin-bottom: 16px;
  }

  .xl-ma-5 {
    margin: 20px;
  }

  .xl-mx-5 {
    margin: 0 20px;
  }

  .xl-my-5 {
    margin: 20px 0;
  }

  .xl-ml-5 {
    margin-left: 20px;
  }

  .xl-mr-5 {
    margin-right: 20px;
  }

  .xl-mt-5 {
    margin-top: 20px;
  }

  .xl-mb-5 {
    margin-bottom: 20px;
  }

  .xl-ma-6 {
    margin: 24px;
  }

  .xl-mx-6 {
    margin: 0 24px;
  }

  .xl-my-6 {
    margin: 24px 0;
  }

  .xl-ml-6 {
    margin-left: 24px;
  }

  .xl-mr-6 {
    margin-right: 24px;
  }

  .xl-mt-6 {
    margin-top: 24px;
  }

  .xl-mb-6 {
    margin-bottom: 24px;
  }

  .xl-ma-7 {
    margin: 28px;
  }

  .xl-mx-7 {
    margin: 0 28px;
  }

  .xl-my-7 {
    margin: 28px 0;
  }

  .xl-ml-7 {
    margin-left: 28px;
  }

  .xl-mr-7 {
    margin-right: 28px;
  }

  .xl-mt-7 {
    margin-top: 28px;
  }

  .xl-mb-7 {
    margin-bottom: 28px;
  }

  .xl-ma-8 {
    margin: 32px;
  }

  .xl-mx-8 {
    margin: 0 32px;
  }

  .xl-my-8 {
    margin: 32px 0;
  }

  .xl-ml-8 {
    margin-left: 32px;
  }

  .xl-mr-8 {
    margin-right: 32px;
  }

  .xl-mt-8 {
    margin-top: 32px;
  }

  .xl-mb-8 {
    margin-bottom: 32px;
  }

  .xl-ma-9 {
    margin: 36px;
  }

  .xl-mx-9 {
    margin: 0 36px;
  }

  .xl-my-9 {
    margin: 36px 0;
  }

  .xl-ml-9 {
    margin-left: 36px;
  }

  .xl-mr-9 {
    margin-right: 36px;
  }

  .xl-mt-9 {
    margin-top: 36px;
  }

  .xl-mb-9 {
    margin-bottom: 36px;
  }

  .xl-ma-10 {
    margin: 40px;
  }

  .xl-mx-10 {
    margin: 0 40px;
  }

  .xl-my-10 {
    margin: 40px 0;
  }

  .xl-ml-10 {
    margin-left: 40px;
  }

  .xl-mr-10 {
    margin-right: 40px;
  }

  .xl-mt-10 {
    margin-top: 40px;
  }

  .xl-mb-10 {
    margin-bottom: 40px;
  }

  .xl-ma-11 {
    margin: 44px;
  }

  .xl-mx-11 {
    margin: 0 44px;
  }

  .xl-my-11 {
    margin: 44px 0;
  }

  .xl-ml-11 {
    margin-left: 44px;
  }

  .xl-mr-11 {
    margin-right: 44px;
  }

  .xl-mt-11 {
    margin-top: 44px;
  }

  .xl-mb-11 {
    margin-bottom: 44px;
  }

  .xl-ma-12 {
    margin: 48px;
  }

  .xl-mx-12 {
    margin: 0 48px;
  }

  .xl-my-12 {
    margin: 48px 0;
  }

  .xl-ml-12 {
    margin-left: 48px;
  }

  .xl-mr-12 {
    margin-right: 48px;
  }

  .xl-mt-12 {
    margin-top: 48px;
  }

  .xl-mb-12 {
    margin-bottom: 48px;
  }

  .xl-ma-13 {
    margin: 52px;
  }

  .xl-mx-13 {
    margin: 0 52px;
  }

  .xl-my-13 {
    margin: 52px 0;
  }

  .xl-ml-13 {
    margin-left: 52px;
  }

  .xl-mr-13 {
    margin-right: 52px;
  }

  .xl-mt-13 {
    margin-top: 52px;
  }

  .xl-mb-13 {
    margin-bottom: 52px;
  }

  .xl-ma-14 {
    margin: 56px;
  }

  .xl-mx-14 {
    margin: 0 56px;
  }

  .xl-my-14 {
    margin: 56px 0;
  }

  .xl-ml-14 {
    margin-left: 56px;
  }

  .xl-mr-14 {
    margin-right: 56px;
  }

  .xl-mt-14 {
    margin-top: 56px;
  }

  .xl-mb-14 {
    margin-bottom: 56px;
  }

  .xl-ma-15 {
    margin: 60px;
  }

  .xl-mx-15 {
    margin: 0 60px;
  }

  .xl-my-15 {
    margin: 60px 0;
  }

  .xl-ml-15 {
    margin-left: 60px;
  }

  .xl-mr-15 {
    margin-right: 60px;
  }

  .xl-mt-15 {
    margin-top: 60px;
  }

  .xl-mb-15 {
    margin-bottom: 60px;
  }

  .xl-ma-16 {
    margin: 64px;
  }

  .xl-mx-16 {
    margin: 0 64px;
  }

  .xl-my-16 {
    margin: 64px 0;
  }

  .xl-ml-16 {
    margin-left: 64px;
  }

  .xl-mr-16 {
    margin-right: 64px;
  }

  .xl-mt-16 {
    margin-top: 64px;
  }

  .xl-mb-16 {
    margin-bottom: 64px;
  }

  .xl-overflow {
    overflow: auto;
  }

  .xl-overflow-hidden {
    overflow: hidden;
  }

  .xl-overflow-visible {
    overflow: visible;
  }

  .xl-overflow-scroll {
    overflow: scroll;
  }

  .xl-x-auto {
    overflow-x: auto;
  }

  .xl-x-hidden {
    overflow-x: hidden;
  }

  .xl-x-visible {
    overflow-x: visible;
  }

  .xl-x-scroll {
    overflow-x: scroll;
  }

  .xl-y-auto {
    overflow-y: auto;
  }

  .xl-y-hidden {
    overflow-y: hidden;
  }

  .xl-y-visible {
    overflow-y: visible;
  }

  .xl-y-scroll {
    overflow-y: scroll;
  }

  .xl-pa-auto {
    padding: auto;
  }

  .xl-pa-0 {
    padding: 0;
  }

  .xl-px-0 {
    padding: 0;
  }

  .xl-py-0 {
    padding: 0;
  }

  .xl-pl-0 {
    padding-left: 0;
  }

  .xl-pr-0 {
    padding-right: 0;
  }

  .xl-pt-0 {
    padding-top: 0;
  }

  .xl-pb-0 {
    padding-bottom: 0;
  }

  .xl-pa-1 {
    padding: 4px;
  }

  .xl-px-1 {
    padding: 0 4px;
  }

  .xl-py-1 {
    padding: 4px 0;
  }

  .xl-pl-1 {
    padding-left: 4px;
  }

  .xl-pr-1 {
    padding-right: 4px;
  }

  .xl-pt-1 {
    padding-top: 4px;
  }

  .xl-pb-1 {
    padding-bottom: 4px;
  }

  .xl-pa-2 {
    padding: 8px;
  }

  .xl-px-2 {
    padding: 0 8px;
  }

  .xl-py-2 {
    padding: 8px 0;
  }

  .xl-pl-2 {
    padding-left: 8px;
  }

  .xl-pr-2 {
    padding-right: 8px;
  }

  .xl-pt-2 {
    padding-top: 8px;
  }

  .xl-pb-2 {
    padding-bottom: 8px;
  }

  .xl-pa-3 {
    padding: 12px;
  }

  .xl-px-3 {
    padding: 0 12px;
  }

  .xl-py-3 {
    padding: 12px 0;
  }

  .xl-pl-3 {
    padding-left: 12px;
  }

  .xl-pr-3 {
    padding-right: 12px;
  }

  .xl-pt-3 {
    padding-top: 12px;
  }

  .xl-pb-3 {
    padding-bottom: 12px;
  }

  .xl-pa-4 {
    padding: 16px;
  }

  .xl-px-4 {
    padding: 0 16px;
  }

  .xl-py-4 {
    padding: 16px 0;
  }

  .xl-pl-4 {
    padding-left: 16px;
  }

  .xl-pr-4 {
    padding-right: 16px;
  }

  .xl-pt-4 {
    padding-top: 16px;
  }

  .xl-pb-4 {
    padding-bottom: 16px;
  }

  .xl-pa-5 {
    padding: 20px;
  }

  .xl-px-5 {
    padding: 0 20px;
  }

  .xl-py-5 {
    padding: 20px 0;
  }

  .xl-pl-5 {
    padding-left: 20px;
  }

  .xl-pr-5 {
    padding-right: 20px;
  }

  .xl-pt-5 {
    padding-top: 20px;
  }

  .xl-pb-5 {
    padding-bottom: 20px;
  }

  .xl-pa-6 {
    padding: 24px;
  }

  .xl-px-6 {
    padding: 0 24px;
  }

  .xl-py-6 {
    padding: 24px 0;
  }

  .xl-pl-6 {
    padding-left: 24px;
  }

  .xl-pr-6 {
    padding-right: 24px;
  }

  .xl-pt-6 {
    padding-top: 24px;
  }

  .xl-pb-6 {
    padding-bottom: 24px;
  }

  .xl-pa-7 {
    padding: 28px;
  }

  .xl-px-7 {
    padding: 0 28px;
  }

  .xl-py-7 {
    padding: 28px 0;
  }

  .xl-pl-7 {
    padding-left: 28px;
  }

  .xl-pr-7 {
    padding-right: 28px;
  }

  .xl-pt-7 {
    padding-top: 28px;
  }

  .xl-pb-7 {
    padding-bottom: 28px;
  }

  .xl-pa-8 {
    padding: 32px;
  }

  .xl-px-8 {
    padding: 0 32px;
  }

  .xl-py-8 {
    padding: 32px 0;
  }

  .xl-pl-8 {
    padding-left: 32px;
  }

  .xl-pr-8 {
    padding-right: 32px;
  }

  .xl-pt-8 {
    padding-top: 32px;
  }

  .xl-pb-8 {
    padding-bottom: 32px;
  }

  .xl-pa-9 {
    padding: 36px;
  }

  .xl-px-9 {
    padding: 0 36px;
  }

  .xl-py-9 {
    padding: 36px 0;
  }

  .xl-pl-9 {
    padding-left: 36px;
  }

  .xl-pr-9 {
    padding-right: 36px;
  }

  .xl-pt-9 {
    padding-top: 36px;
  }

  .xl-pb-9 {
    padding-bottom: 36px;
  }

  .xl-pa-10 {
    padding: 40px;
  }

  .xl-px-10 {
    padding: 0 40px;
  }

  .xl-py-10 {
    padding: 40px 0;
  }

  .xl-pl-10 {
    padding-left: 40px;
  }

  .xl-pr-10 {
    padding-right: 40px;
  }

  .xl-pt-10 {
    padding-top: 40px;
  }

  .xl-pb-10 {
    padding-bottom: 40px;
  }

  .xl-pa-11 {
    padding: 44px;
  }

  .xl-px-11 {
    padding: 0 44px;
  }

  .xl-py-11 {
    padding: 44px 0;
  }

  .xl-pl-11 {
    padding-left: 44px;
  }

  .xl-pr-11 {
    padding-right: 44px;
  }

  .xl-pt-11 {
    padding-top: 44px;
  }

  .xl-pb-11 {
    padding-bottom: 44px;
  }

  .xl-pa-12 {
    padding: 48px;
  }

  .xl-px-12 {
    padding: 0 48px;
  }

  .xl-py-12 {
    padding: 48px 0;
  }

  .xl-pl-12 {
    padding-left: 48px;
  }

  .xl-pr-12 {
    padding-right: 48px;
  }

  .xl-pt-12 {
    padding-top: 48px;
  }

  .xl-pb-12 {
    padding-bottom: 48px;
  }

  .xl-pa-13 {
    padding: 52px;
  }

  .xl-px-13 {
    padding: 0 52px;
  }

  .xl-py-13 {
    padding: 52px 0;
  }

  .xl-pl-13 {
    padding-left: 52px;
  }

  .xl-pr-13 {
    padding-right: 52px;
  }

  .xl-pt-13 {
    padding-top: 52px;
  }

  .xl-pb-13 {
    padding-bottom: 52px;
  }

  .xl-pa-14 {
    padding: 56px;
  }

  .xl-px-14 {
    padding: 0 56px;
  }

  .xl-py-14 {
    padding: 56px 0;
  }

  .xl-pl-14 {
    padding-left: 56px;
  }

  .xl-pr-14 {
    padding-right: 56px;
  }

  .xl-pt-14 {
    padding-top: 56px;
  }

  .xl-pb-14 {
    padding-bottom: 56px;
  }

  .xl-pa-15 {
    padding: 60px;
  }

  .xl-px-15 {
    padding: 0 60px;
  }

  .xl-py-15 {
    padding: 60px 0;
  }

  .xl-pl-15 {
    padding-left: 60px;
  }

  .xl-pr-15 {
    padding-right: 60px;
  }

  .xl-pt-15 {
    padding-top: 60px;
  }

  .xl-pb-15 {
    padding-bottom: 60px;
  }

  .xl-pa-16 {
    padding: 64px;
  }

  .xl-px-16 {
    padding: 0 64px;
  }

  .xl-py-16 {
    padding: 64px 0;
  }

  .xl-pl-16 {
    padding-left: 64px;
  }

  .xl-pr-16 {
    padding-right: 64px;
  }

  .xl-pt-16 {
    padding-top: 64px;
  }

  .xl-pb-16 {
    padding-bottom: 64px;
  }

  .xl-static {
    position: static;
  }

  .xl-fixed {
    position: fixed;
  }

  .xl-absolute {
    position: absolute;
  }

  .xl-relative {
    position: relative;
  }

  .xl-sticky {
    position: sticky;
  }

  .xl-top-0 {
    top: 0;
  }

  .xl-top-1 {
    top: 2px;
  }

  .xl-top-2 {
    top: 4px;
  }

  .xl-top-3 {
    top: 6px;
  }

  .xl-top-4 {
    top: 8px;
  }

  .xl-top-5 {
    top: 10px;
  }

  .xl-top-6 {
    top: 12px;
  }

  .xl-top-7 {
    top: 14px;
  }

  .xl-top-8 {
    top: 16px;
  }

  .xl-top-9 {
    top: 18px;
  }

  .xl-top-10 {
    top: 20px;
  }

  .xl-top-11 {
    top: 22px;
  }

  .xl-top-12 {
    top: 24px;
  }

  .xl-top-13 {
    top: 26px;
  }

  .xl-top-14 {
    top: 28px;
  }

  .xl-top-15 {
    top: 30px;
  }

  .xl-top-16 {
    top: 32px;
  }

  .xl-bottom-0 {
    bottom: 0;
  }

  .xl-bottom-1 {
    bottom: 2px;
  }

  .xl-bottom-2 {
    bottom: 4px;
  }

  .xl-bottom-3 {
    bottom: 6px;
  }

  .xl-bottom-4 {
    bottom: 8px;
  }

  .xl-bottom-5 {
    bottom: 10px;
  }

  .xl-bottom-6 {
    bottom: 12px;
  }

  .xl-bottom-7 {
    bottom: 14px;
  }

  .xl-bottom-8 {
    bottom: 16px;
  }

  .xl-bottom-9 {
    bottom: 18px;
  }

  .xl-bottom-10 {
    bottom: 20px;
  }

  .xl-bottom-11 {
    bottom: 22px;
  }

  .xl-bottom-12 {
    bottom: 24px;
  }

  .xl-bottom-13 {
    bottom: 26px;
  }

  .xl-bottom-14 {
    bottom: 28px;
  }

  .xl-bottom-15 {
    bottom: 30px;
  }

  .xl-bottom-16 {
    bottom: 32px;
  }

  .xl-left-0 {
    left: 0;
  }

  .xl-left-1 {
    left: 2px;
  }

  .xl-left-2 {
    left: 4px;
  }

  .xl-left-3 {
    left: 6px;
  }

  .xl-left-4 {
    left: 8px;
  }

  .xl-left-5 {
    left: 10px;
  }

  .xl-left-6 {
    left: 12px;
  }

  .xl-left-7 {
    left: 14px;
  }

  .xl-left-8 {
    left: 16px;
  }

  .xl-left-9 {
    left: 18px;
  }

  .xl-left-10 {
    left: 20px;
  }

  .xl-left-11 {
    left: 22px;
  }

  .xl-left-12 {
    left: 24px;
  }

  .xl-left-13 {
    left: 26px;
  }

  .xl-left-14 {
    left: 28px;
  }

  .xl-left-15 {
    left: 30px;
  }

  .xl-left-16 {
    left: 32px;
  }

  .xl-right-0 {
    right: 0;
  }

  .xl-right-1 {
    right: 2px;
  }

  .xl-right-2 {
    right: 4px;
  }

  .xl-right-3 {
    right: 6px;
  }

  .xl-right-4 {
    right: 8px;
  }

  .xl-right-5 {
    right: 10px;
  }

  .xl-right-6 {
    right: 12px;
  }

  .xl-right-7 {
    right: 14px;
  }

  .xl-right-8 {
    right: 16px;
  }

  .xl-right-9 {
    right: 18px;
  }

  .xl-right-10 {
    right: 20px;
  }

  .xl-right-11 {
    right: 22px;
  }

  .xl-right-12 {
    right: 24px;
  }

  .xl-right-13 {
    right: 26px;
  }

  .xl-right-14 {
    right: 28px;
  }

  .xl-right-15 {
    right: 30px;
  }

  .xl-right-16 {
    right: 32px;
  }

  .xl-z-index-0 {
    z-index: 0;
  }

  .xl-z-index-1 {
    z-index: 1;
  }

  .xl-z-index-2 {
    z-index: 2;
  }

  .xl-z-index-3 {
    z-index: 3;
  }

  .xl-z-index-auto {
    z-index: auto;
  }

  .xl-z-index-top {
    z-index: 99999;
  }

  .xl-z-index-bottom {
    z-index: -99999;
  }

  .xl-row {
    display: flex;
    flex-wrap: wrap;
  }

  .xl-col-1 {
    flex: 0 0 8.3333%;
    max-width: 8.3333%;
  }

  .xl-col-2 {
    flex: 0 0 16.6667%;
    max-width: 16.6667%;
  }

  .xl-col-3 {
    flex: 0 0 25%;
    max-width: 25%;
  }

  .xl-col-4 {
    flex: 0 0 33.3333%;
    max-width: 33.3333%;
  }

  .xl-col-5 {
    flex: 0 0 41.6667%;
    max-width: 41.6667%;
  }

  .xl-col-6 {
    flex: 0 0 50%;
    max-width: 50%;
  }

  .xl-col-7 {
    flex: 0 0 58.3333%;
    max-width: 58.3333%;
  }

  .xl-col-8 {
    flex: 0 0 66.6667%;
    max-width: 66.6667%;
  }

  .xl-col-9 {
    flex: 0 0 75%;
    max-width: 75%;
  }

  .xl-col-10 {
    flex: 0 0 83.3333%;
    max-width: 83.3333%;
  }

  .xl-col-11 {
    flex: 0 0 91.6667%;
    max-width: 91.6667%;
  }

  .xl-col-12 {
    flex: 0 0 100%;
    max-width: 100%;
  }

  .xl-h-auto {
    height: auto;
  }

  .xl-h-full {
    height: 100%;
  }

  .xl-h-screen {
    height: 100vh;
  }

  .xl-w-auto {
    width: auto;
  }

  .xl-w-full {
    width: 100%;
  }

  .xl-w-screen {
    width: 100vw;
  }

  .xl-text-center {
    text-align: center;
  }

  .xl-text-left {
    text-align: left;
  }

  .xl-text-right {
    text-align: right;
  }

  .xl-font-bold {
    font-weight: bold;
  }

  .xl-font-normal {
    font-weight: normal;
  }

  .xl-font-light {
    font-weight: light;
  }

  .xl-font-italic {
    font-style: italic;
  }

  .xl-font-underline {
    text-decoration: underline;
  }

  .xl-font-overline {
    text-decoration: overline;
  }

  .xl-font-line-through {
    text-decoration: line-through;
  }
}
