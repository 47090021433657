@import './variables';

* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  width: 1rem;
}

::-webkit-scrollbar-track {
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: #c5d3e0;
  border-radius: 1.25rem;
  border: 0.375rem solid transparent;
  background-clip: content-box;
}

body {
  overflow: hidden;
  font-weight: 500;
}

img {
  display: block;
}

.sidebar-menu .ant-menu-item,
.ant-menu-submenu-title {
  height: 50px !important;
}

.sidebar-menu .ant-menu-item,
.ant-menu-title-content {
  font-size: 16px !important;
}

.sidebar-menu .ant-menu-item .ant-menu-title-content {
  margin-inline-start: 15px !important;
}

.sidebar-menu .ant-menu-submenu-title .ant-menu-title-content {
  margin-inline-start: 15px !important;
}

.sidebar-menu .ant-menu-item,
.ant-menu-item-icon {
  font-size: 18px !important;
}

/************************* Antd Pagination Override CSS Start *************************************/

.ant-pagination-prev,
.ant-pagination-next,
.ant-pagination-jump-prev,
.ant-pagination-jump-next,
.ant-pagination-item-link,
.ant-pagination-item {
  min-width: 2.0625rem !important;
  height: 2.0625rem !important;
  line-height: 2.0625rem !important;
  border-radius: 0 !important;
  font-size: 0.875rem !important;
  margin-right: 4px !important;
  border-color: rgb(0 0 0 / 25%) !important;
}

.ant-pagination-prev:hover,
.ant-pagination-next:hover,
.ant-pagination-jump-prev:hover,
.ant-pagination-jump-next:hover,
.ant-pagination-item-link:hover,
.ant-pagination-item:hover {
  border-color: #121212 !important;
}

.ant-pagination-item-active {
  border-color: #121212 !important;
}

.ant-pagination-item-active a {
  color: #121212 !important;
}

.ant-pagination-item:hover a {
  border-color: #121212 !important;
}

.ant-pagination-prev .ant-pagination-item-link,
.ant-pagination-next .ant-pagination-item-link {
  border-radius: 0 !important;
}

.ant-pagination-item-container .ant-pagination-item-ellipsis {
  color: rgb(0 0 0 / 25%) !important;
}

.ant-pagination-disabled {
  .ant-pagination-item-link,
  .ant-pagination-item a {
    color: rgb(0 0 0 / 25%) !important;
  }
}

.ant-pagination .ant-pagination-disabled {
  .ant-pagination-item-link,
  .ant-pagination-item a {
    border-color: rgb(0 0 0 / 25%) !important;
  }
}

.ant-pagination-options-size-changer {
  min-width: 2.0625rem !important;
  height: 2.0625rem !important;
  line-height: 2.0625rem !important;
}

/************************* Antd Pagination Override CSS End *************************************/

.ant-form-item-label label {
  font-weight: 600 !important;
  color: #404040 !important;
}

/************************* Antd Drawer **********************************/
.ant-drawer-close {
  position: absolute;
  top: 20px;
  right: 0;
}

/********************** Antd Model **********************************/
.ant-modal-content {
  padding: 0px !important;
}

.ant-modal-header {
  padding: 16px 24px !important;
  border-bottom: 1px solid #e8e8e8 !important;
  margin-bottom: 0px !important;
}

.ant-modal-title {
  font-size: 16px !important;
  font-weight: 500 !important;
}

.ant-modal .ant-modal-close-x {
  line-height: 26px !important;
}

.ant-modal-close-x svg {
  height: 18px !important;
  width: 18px !important;
}

.ant-modal-body {
  padding: 20px 30px !important;
}

.ant-modal-footer {
  padding: 10px 16px !important;
  border-top: 1px solid #e8e8e8 !important;
  margin-top: 0px !important;
}

/* -------------------------Dashboard Layout-------------------------------- */
.main-layout {
  min-height: 100vh;
}

.content-layout {
  margin-left: 260px !important;

  @media only screen and (width <= 1024px) {
    margin-left: unset !important;
  }
}

.content-area {
  padding: 1.25rem 2.25rem;
  overflow: auto;
  height: 80vh;

  @media only screen and (width <= 768px) {
    padding: 0.75rem 1rem;
  }
}

.sidebar-area {
  position: fixed !important;
  left: 0 !important;
  top: 0 !important;
  bottom: 0 !important;
  background: #121212 !important;
  width: 260px !important;
  min-width: 260px !important;
  z-index: 10 !important;
  transition: all 0.6s ease;

  @media only screen and (width <= 1024px) {
    width: 0 !important;
    min-width: 0 !important;
    transition: all 0.6s ease;
  }
}

.active-sidebar-area {
  width: 260px !important;
  min-width: 260px !important;
  transition: all 0.6s ease;
}

.mobile-menu {
  cursor: pointer;
  visibility: hidden;

  @media only screen and (width <= 1024px) {
    visibility: visible;
  }
}

.collape-menu {
  visibility: hidden;

  @media only screen and (width <= 1024px) {
    visibility: visible;
  }
}

.sidebar-logo {
  height: 5rem;
  padding: 0.8rem 1.25rem;
  margin: 0.6rem 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-link {
  display: flex;
  align-items: center;
  overflow: hidden;
  position: relative;
}

.mobile-menu {
  cursor: pointer;
  visibility: hidden;

  @media only screen and (width <= 1024px) {
    visibility: visible;
    margin-left: 100px;
  }
}

.sider-content {
  overflow: hidden auto;
  max-height: calc(100vh - 4.25rem);

  @media only screen and (width >= 768px) {
    max-height: calc(100vh - 6.625rem);
  }
}

.sidebar-menu {
  background: transparent;
  border-right: 0;
}

.sidebar-menu a {
  width: 100%;
  display: block;
}

.sidebar-menu .ant-menu-item,
.ant-menu-submenu {
  font-size: 0.875rem !important;
  font-size: 15px !important;
}

.sidebar-menu .ant-menu-item-icon {
  width: 1.25rem !important;
}

.sidebar-menu .ant-menu-item,
.ant-menu-submenu {
  color: #fff;
  fill: #fff;
}

.sidebar-menu .ant-menu-item:hover,
.ant-menu-submenu-title:hover {
  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow,
  span[role='img'],
  a,
  .ant-menu-item-icon,
  .ant-menu-title-content {
    color: #ffb765;
    fill: #ffb765;
  }
}

.sidebar-menu .ant-menu-submenu-selected {
  .ant-menu-submenu-title {
    color: #ffb765;

    .ant-menu-submenu-expand-icon,
    .ant-menu-submenu-arrow,
    span[role='img'] {
      color: #ffb765;
      fill: #ffb765;
    }
  }
}

.sidebar-menu .ant-menu-item-selected {
  background-color: transparent !important;

  .ant-menu-submenu-expand-icon,
  .ant-menu-submenu-arrow,
  span[role='img'],
  .ant-menu-item-icon,
  a {
    color: #ffb765;
    fill: #ffb765;
  }
}

.sidebar-menu .ant-menu-item-active,
.ant-menu-submenu-active .ant-menu-submenu-title {
  background-color: transparent !important;
}

/* --------------------- Header Layout ------------------------------ */

.header-layout {
  line-height: 1;
  padding: 1.25rem 2.25rem;
  height: 5rem;
  background-color: #fff !important;
  position: sticky !important;
  top: 0 !important;
  z-index: 6 !important;
}

.header-action-wrapper {
  cursor: pointer;

  & > .ant-btn > span[role='img'],
  .ant-badge {
    font-size: 1.625rem;

    @media only screen and (width <= 786px) {
      font-size: 1.25rem;
    }
  }

  & .ant-badge {
    display: inline-block;
  }
}

/* ---------------------- Auth Layout ------------------------ */

.auth-wrapper {
  height: 100vh;
  overflow-y: auto;
  width: 100vw;
  display: flex;
}

.auth-bg-wrapper {
  width: 100%;
  height: 100%;
  background: url('../images/map.svg');
  background-size: cover;
  position: relative;
}

.login-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}

/* ------------------ Footer -------------------------- */

.footer-text {
  font-size: 16px;
  font-weight: 500;
  color: #404040;
}

.footer-text span {
  font-weight: 600;
  color: #121212;
}
